import {
  CPU_DATA,
  GPU_DATA,
  CPU_PRICE,
  GPU_PRICE,
  RAYVISION_CPUS,
  RAYVISION_GHZ,
  RAYVISION_SCORES,
  RAYVISION_CPU_NUMBER,
  RAYVISION_CPU_SCORE,
  RAYVISION_GPU_POWER,
  RAYVISION_GPU_NUMBER,
  RAYVISION_GPU_NUMBER_LIST,
} from './constants'

class CPUSKU {
  constructor() {
    this.cpuData = CPU_DATA.sort((a, b) => {
      if (a.Processor < b.Processor) {
        return 1
      } else {
        return -1
      }
    })
    this.defaultProcessor = 'Intel Xeon E5-2660 v2 '
    this.defaultMember = 0
  }
  // 获取不同品牌的处理器类型
  getProcessor() {
    return this.cpuData.map(val => val.Processor)
  }
  // 获取会员列表
  get getMember() {
    return ['钻石会员', '白金会员', '黄金会员', '白银会员', '普通会员']
  }
  // 获取会员价
  getMemberPrice(idx) {
    return CPU_PRICE[idx]
  }
  // 获取选择的scores
  getScores(processor) {
    let scores = 0
    this.cpuData.forEach(val => {
      if (val.Processor === processor) scores = val.Score
    })
    return scores
  }
  // 获取选择的GHz
  getGHz(processor) {
    let GHz = 0
    this.cpuData.forEach(val => {
      if (val.Processor === processor) GHz = val.GHz
    })
    return GHz
  }
  // 默认的机器数
  getDefaultMachineNumber(frames) {
    if (0 <= frames && frames <= 500) {
      return frames
    }
    if (500 < frames && frames <= 1000) {
      return 500
    }
    if (1000 < frames && frames <= 10000) {
      return 1000
    }
    if (10000 < frames) {
      return Math.round(frames / 10) <= 5000 ? Math.round(frames / 10) : 5000
    }
  }
}
// 分钟转小时
function toHourMinute(minutes) {
  return Math.floor(minutes / 60) + '小时' + (minutes % 60) + '分'
}
// 用户本地渲染时长, 帧数 * 时间
function getUserLocalRenderTimes(frames, times) {
  let time = frames * times || 0
  return toHourMinute(time)
}
// 使用renderbus渲染时长， 帧数 * 时间 * 计算力 / 机器数
function getRenderbusRenderTimes(frames, times, computingPower, machine) {
  let time = Math.floor((frames * times * computingPower) / machine) || 0
  return toHourMinute(time)
}
// CPU渲染费用， 帧数*时间*计算力 /60 *单价 * 16核数
function getUserRenderCost(frames, times, computingPower, price) {
  return Math.floor(((frames * times * computingPower) / 60) * price * RAYVISION_SCORES)
}
// CPU 计算力， 【用户本地CPU跑分*GHz*2】 /【1510*2.2*2 】   1510为CPU本地跑分
function getComputingPower(score, GHz) {
  return (
    (Number(score) * Number(GHz) * RAYVISION_CPU_NUMBER) /
    (RAYVISION_CPU_SCORE * RAYVISION_GHZ * RAYVISION_CPUS)
  )
}
function calcCPUResult({ price, frames, times, machine, scores, GHz }) {
  let computingPower = getComputingPower(scores, GHz)
  return {
    userLocalRenderTimes: getUserLocalRenderTimes(frames, times),
    renderbusRenderTimes: getRenderbusRenderTimes(frames, times, computingPower, machine),
    userRenderCost: getUserRenderCost(frames, times, computingPower, price),
  }
}
class GPUSKU {
  constructor() {
    this.gpuData = GPU_DATA.sort((a, b) => {
      if (a.graphicsCard < b.graphicsCard) {
        return 1
      } else {
        return -1
      }
    })
    this.defaultGraphicsCard = 'GTX 1080 Ti'
    this.defaultCard = 2
    this.defaultMember = 0
  }
  // 获取显卡类型
  get getGraphicsCard() {
    return this.gpuData.map(val => val.graphicsCard)
  }
  // 获取显卡列表
  get getCardList() {
    return RAYVISION_GPU_NUMBER_LIST
  }
  // 显卡张数
  getGPUNumber(number) {
    return RAYVISION_GPU_NUMBER_LIST[number]
  }
  // 获取会员列表
  get getMember() {
    return ['钻石会员', '白金会员', '黄金会员', '白银会员', '普通会员']
  }
  // 获取会员价
  getMemberPrice(idx) {
    return GPU_PRICE[idx]
  }
  // 获取选择的scores
  getScores(graphicsCard) {
    let scores = 0
    this.gpuData.forEach(val => {
      if (val.graphicsCard === graphicsCard) scores = Number(val.Score)
    })
    return scores
  }
  // 默认的机器数
  getDefaultMachineNumber(frames) {
    if (0 <= frames && frames <= 500) {
      return frames
    }
    if (500 < frames && frames <= 1000) {
      return 500
    }
    if (1000 < frames) {
      return Math.round(frames / 10) <= 1000 ? Math.round(frames / 10) : 1000
    }
  }
}

// 用户本地渲染时长, 帧数 * 时间
function getGPUUserLocalRenderTimes(frames, times) {
  let time = frames * times || 0
  return toHourMinute(time)
}
// 使用renderbus渲染时长， 帧数 * 时间 * 计算力 / 机器数
function getGPURenderbusRenderTimes(frames, times, computingPower, machine) {
  let time = Math.floor((frames * times * computingPower) / machine) || 0
  return toHourMinute(time)
}
// GPU渲染费用， 帧数*时间*计算力 /60 *单价*16核数
function getGPUUserRenderCost(frames, times, computingPower, price) {
  return Math.floor(((frames * times * computingPower) / 60) * price)
}
// GPU计算能力（云端）：【用户本地GPU型号计算能力（scores）*显卡数】/【GTX1080计算能力（191）*2】
function getGPUComputingPower(scores, card) {
  return (scores * card) / (RAYVISION_GPU_POWER * RAYVISION_GPU_NUMBER)
}

function calcGPUResult({ price, frames, times, machine, scores, card }) {
  let computingPower = getGPUComputingPower(scores, card)
  return {
    userLocalRenderTimes: getGPUUserLocalRenderTimes(frames, times),
    renderbusRenderTimes: getGPURenderbusRenderTimes(frames, times, computingPower, machine),
    userRenderCost: getGPUUserRenderCost(frames, times, computingPower, price),
  }
}
export default {
  CPUSKU: new CPUSKU(),
  GPUSKU: new GPUSKU(),
  calcCPUResult,
  calcGPUResult,
}
