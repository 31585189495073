import React from 'react'
import PropTypes from 'prop-types'
import { H2, Button } from '@renderbus/common/components'

import OrdinaryIcon from './icons/ordinary.png'
import SilverIcon from './icons/silver.png'
import GoldIcon from './icons/gold.png'
import PlatinumIcon from './icons/platinum.png'
import DiamondIcon from './icons/diamond.png'
import { CardContainer, Super, PriceContainer } from './atoms'

export const PriceRank = Object.freeze({
  Ordinary: Symbol('ordinary'),
  Silver: Symbol('silver'),
  Gold: Symbol('Gold'),
  Platinum: Symbol('platinum'),
  Diamond: Symbol('diamond'),
})
class PriceCard extends React.PureComponent {
  static rankIconMap = new Map([
    [PriceRank.Ordinary, OrdinaryIcon],
    [PriceRank.Silver, SilverIcon],
    [PriceRank.Gold, GoldIcon],
    [PriceRank.Platinum, PlatinumIcon],
    [PriceRank.Diamond, DiamondIcon],
  ])
  static rankTitleMap = new Map([
    [PriceRank.Ordinary, '普通会员'],
    [PriceRank.Silver, '白银会员'],
    [PriceRank.Gold, '黄金会员'],
    [PriceRank.Platinum, '铂金会员'],
    [PriceRank.Diamond, '钻石会员'],
  ])
  static rankTriggerMap = new Map([
    [PriceRank.Ordinary, '累计充值<3000元'],
    [PriceRank.Silver, '累计充值满3000元'],
    [PriceRank.Gold, '累计充值满10000元'],
    [PriceRank.Platinum, '累计充值满20000元'],
    [PriceRank.Diamond, '累计充值满30000元'],
  ])
  render() {
    const { rank, price, alt, unit, children, isActive, theme, ...rest } = this.props
    return (
      <CardContainer bg={theme} isActive={isActive} {...rest}>
        <img src={PriceCard.rankIconMap.get(rank)} alt={alt} />
        <H2>{PriceCard.rankTitleMap.get(rank)}</H2>
        <PriceContainer>
          <Super>¥{price}</Super>
          <span>/{unit}</span>
        </PriceContainer>
        <Button
          as='a'
          variant='outlined'
          color={isActive ? 'default' : 'primary'}
          href='https://task.renderbus.com/center/user/topUp'
          rel='nofollow'
        >
          {PriceCard.rankTriggerMap.get(rank)}
        </Button>
        {children}
      </CardContainer>
    )
  }
  handle
}

PriceCard.propTypes = {
  unit: PropTypes.string,
  alt: PropTypes.string,
  price: PropTypes.number,
  theme: PropTypes.oneOf(['default', 'dark']),
  rank: PropTypes.oneOf(Object.values(PriceRank)),
}

export default PriceCard
