import React from 'react'
import { graphql, Link } from 'gatsby'
import { H1, Flex, Button, Wrapper, Billboard, SEO } from '@renderbus/common/components'
import Layout from '../molecules/layout'
import RegisterBanner from '../molecules/register-banner'
import PriceCalculator from '../molecules/pricing/price-calculator'
import { PriceTable } from '../molecules/pricing/price-card'
import { AdvertisingSpaceWrap } from './film-tv-rendering.atom'
import BannerBg from '../images/pricing/banner.png'
import BannerBgMb from '../images/pricing/banner-mb.png'

import {
  BannerContainer,
  DesktopOnlySection,
  YouthCloudContainer,
  PriceSection,
  PriceTitle,
  PriceLightSection,
  CPUPriceTitle,
  PriceSubTitle,
  PriceTips,
  H2,
  BlackBg,
  NewBillboard,
} from './pricing.atom'

class Pricing extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='电影云渲染价格,电视/动画云渲染价格-Renderbus云渲染农场'
          description='Renderbus瑞云渲染——影视动画云渲染价格低至0.0375元/核时,支持CPU,GPU渲染,新用户注册即送40元无门槛渲染券,免费体验高速云渲染服务_Renderbus瑞云奥斯卡作品渲染农场'
          keywords='电影云渲染价格,电视/动画云渲染价格,云渲染怎么收费'
          sharePostSlug='pricing.html'
        />
        <AdvertisingSpaceWrap to='/ysdhnzhd.html#ysdh' marginTop='60px'>
          {/* ad here */}
        </AdvertisingSpaceWrap>
        <BannerContainer>
          <Flex direction='column' style={{ height: '100%' }} valign='flex-start'>
            <H1>渲染农场价格</H1>
            <p>按量计费，渲染成本比自购机器节省70%以上</p>
          </Flex>
          <NewBillboard className='pc'>
            <img src={BannerBg} alt='' />
          </NewBillboard>
          <NewBillboard className='mb'>
            <img src={BannerBgMb} alt='' />
          </NewBillboard>
          <BlackBg />
        </BannerContainer>
        <PriceSection>
          <BlackBg />
          <Wrapper>
            <CPUPriceTitle>GPU渲染价格</CPUPriceTitle>
            <PriceTable />
            <PriceTips>
              <Flex halign='flex-start'>
                GPU计算公式：GPU价格 x 渲染时长
                <br />
                *备注：
                <br />
                1.GPU默认双卡，如果选用机器是单卡或四卡，实际收费则按卡数倍数计算
                <br />
                2.内存默认64GB，如选择更多内存的机器，会有额外费用
                <br />
                3.部分渲染软件需要额外收费，提交作业时会费用提示
              </Flex>
            </PriceTips>
          </Wrapper>
        </PriceSection>
        <PriceLightSection>
          <Wrapper>
            <CPUPriceTitle>CPU渲染价格</CPUPriceTitle>
            <PriceSubTitle>*新用户注册约可免费渲染10小时</PriceSubTitle>
            <PriceTable type='cpu' />
            <PriceTips>
              <Flex halign='flex-start'>
                CPU计算公式：CPU价格 x 渲染时长
                <br />
                *备注：
                <br />
                1.CPU价格按渲染机器核数计费，CPU核时单价 x 物理核数
                <br />
                2.内存默认64GB，如选择更多内存的机器，会有额外费用
                <br />
                3.部分渲染软件需要额外收费，提交作业时会费用提示
              </Flex>
            </PriceTips>
          </Wrapper>
        </PriceLightSection>
        <DesktopOnlySection>
          <PriceTitle>渲染成本估算器</PriceTitle>
          <PriceCalculator />
        </DesktopOnlySection>
        <YouthCloudContainer>
          <Wrapper style={{ height: '100%' }}>
            <Flex direction='column' valign='space-evenly' style={{ height: '100%' }}>
              <H2>青云平台及教育优惠</H2>
              <p>
                “青云”计划是瑞云推出的一项旨在帮助CG专业新生潜力军成长，扶持前瞻性创意小团队实现梦想的方案。
                <br />
                该方案包括“教育优惠计划”和“青云平台”。
              </p>
              <div>
                <Button
                  variant='outlined'
                  color='primary'
                  as={Link}
                  to='/gocloud.html'
                  rel='nofollow'
                  className='pc'
                >
                  教育优惠计划
                </Button>
                <Button as={Link} to='/gocloud.html' rel='nofollow' className='mb'>
                  教育优惠计划
                </Button>
                <Button as={Link} to='/go-cloud.html' style={{ marginLeft: 20 }}>
                  青云平台
                </Button>
              </div>
            </Flex>
          </Wrapper>
          <Billboard fluid={data.youthCloud.childImageSharp.fluid} />
          <Billboard
            fluid={[
              data.youthCloud.childImageSharp.fluid,
              { ...data.youthCloudMb.childImageSharp.fluid, media: `(max-width: 780px)` },
            ]}
          />
        </YouthCloudContainer>
        <RegisterBanner />
      </Layout>
    )
  }
}

export default Pricing

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/pricing/banner.png/" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { regex: "/pricing/banner-mb.png/" }) {
      ...fluidImage
    }
    youthCloud: file(relativePath: { regex: "/pricing/youth-cloud.png/" }) {
      ...fluidImage
    }
    youthCloudMb: file(relativePath: { regex: "/pricing/youth-cloud-mb.png/" }) {
      ...fluidImage
    }
  }
`
