import { css } from 'styled-components'
import OrdinaryIcon from './icons/ordinary.png'
import SilverIcon from './icons/silver.png'
import GoldIcon from './icons/gold.png'
import PlatinumIcon from './icons/platinum.png'
import DiamondIcon from './icons/diamond.png'

import OrdinaryImg from './images/normal.png'
import SilverImg from './images/silver.png'
import GoldImg from './images/gold.png'
import PlatinumImg from './images/platinum.png'
import DiamondImg from './images/diamond.png'
import OrdinaryHoverImg from './images/normal-hover.png'
import SilverHoverImg from './images/silver-hover.png'
import GoldHoverImg from './images/gold-hover.png'
import PlatinumHoverImg from './images/platinum-hover.png'
import DiamondHoverImg from './images/diamond-hover.png'
import OrdinaryCPUHoverImg from './images/cpu-normal/normal-hover.png'
import SilverCPUHoverImg from './images/cpu-normal/silver-hover.png'
import GoldCPUHoverImg from './images/cpu-normal/gold-hover.png'
import PlatinumCPUHoverImg from './images/cpu-normal/platinum-hover.png'
import DiamondCPUHoverImg from './images/cpu-normal/diamond-hover.png'
import OrdinaryMbImg from './images/mobile/normal.png'
import SilverMbImg from './images/mobile/silver.png'
import GoldMbImg from './images/mobile/gold.png'
import PlatinumMbImg from './images/mobile/platinum.png'
import DiamondMbImg from './images/mobile/diamond.png'

export const PriceRank = Object.freeze({
  Ordinary: Symbol('ordinary'),
  Silver: Symbol('silver'),
  Gold: Symbol('Gold'),
  Platinum: Symbol('platinum'),
  Diamond: Symbol('diamond'),
})

export const rankColorMap = new Map([
  [PriceRank.Ordinary, '#1AFFB2'],
  [PriceRank.Silver, '#CCE1FF'],
  [PriceRank.Gold, '#FFD9B2'],
  [PriceRank.Platinum, '#DDCCFF'],
  [PriceRank.Diamond, '#FFDD99'],
])

export const rankLinMap = new Map([
  [
    PriceRank.Ordinary,
    css`
      background: linear-gradient(90deg, #13cb75 0%, #1aff8c 100%);
      box-shadow: 0px 2px 4px 0px rgba(61, 204, 133, 0.3);
    `,
  ],
  [
    PriceRank.Silver,
    css`
      background: linear-gradient(-90deg, #cce1ff 0%, #99c3ff 100%);
      box-shadow: 0px 2px 4px 0px rgba(61, 121, 204, 0.3);
    `,
  ],
  [
    PriceRank.Gold,
    css`
      background: linear-gradient(-90deg, #ffd9b2 0%, #ffbf80 100%);
      box-shadow: 0px 2px 4px 0px rgba(204, 133, 61, 0.3);
    `,
  ],
  [
    PriceRank.Platinum,
    css`
      background: linear-gradient(-90deg, #ddccff 0%, #bb99ff 100%);
      box-shadow: 0px 2px 4px 0px rgba(109, 61, 204, 0.3);
    `,
  ],
  [
    PriceRank.Diamond,
    css`
      background: linear-gradient(-90deg, #ffdd99 0%, #ffcc66 100%);
      box-shadow: 0px 2px 4px 0px rgba(204, 156, 61, 0.3);
    `,
  ],
])

export const rankBgMap = new Map([
  [PriceRank.Ordinary, [OrdinaryImg, OrdinaryHoverImg, OrdinaryCPUHoverImg, OrdinaryMbImg]],
  [PriceRank.Silver, [SilverImg, SilverHoverImg, SilverCPUHoverImg, SilverMbImg]],
  [PriceRank.Gold, [GoldImg, GoldHoverImg, GoldCPUHoverImg, GoldMbImg]],
  [PriceRank.Platinum, [PlatinumImg, PlatinumHoverImg, PlatinumCPUHoverImg, PlatinumMbImg]],
  [PriceRank.Diamond, [DiamondImg, DiamondHoverImg, DiamondCPUHoverImg, DiamondMbImg]],
])

export const rankIconMap = new Map([
  [PriceRank.Ordinary, OrdinaryIcon],
  [PriceRank.Silver, SilverIcon],
  [PriceRank.Gold, GoldIcon],
  [PriceRank.Platinum, PlatinumIcon],
  [PriceRank.Diamond, DiamondIcon],
])
export const rankTitleMap = new Map([
  [PriceRank.Ordinary, '普通用户'],
  [PriceRank.Silver, '白银会员'],
  [PriceRank.Gold, '黄金会员'],
  [PriceRank.Platinum, '铂金会员'],
  [PriceRank.Diamond, '钻石会员'],
])
export const priceListMap = new Map([
  [PriceRank.Ordinary, '5'],
  [PriceRank.Silver, '4'],
  [PriceRank.Gold, '3.5'],
  [PriceRank.Platinum, '3'],
  [PriceRank.Diamond, '2.5'],
])
export const rankTriggerMap = new Map([
  [PriceRank.Ordinary, '累计充值<3000元'],
  [PriceRank.Silver, '累计充值满3000元'],
  [PriceRank.Gold, '累计充值满10000元'],
  [PriceRank.Platinum, '累计充值满20000元'],
  [PriceRank.Diamond, '累计充值满30000元'],
])
export const rankGraphicsCardMap = new Map([
  [PriceRank.Ordinary, ['1080Ti', '2080Ti', '3060', '3090', 'Tesla T4']],
  [PriceRank.Silver, ['1080Ti', '2080Ti', '3060', '3090', 'Tesla T4']],
  [PriceRank.Gold, ['1080Ti', '2080Ti', '3060', '3090', 'Tesla T4']],
  [PriceRank.Platinum, ['1080Ti', '2080Ti', '3060', '3090', 'Tesla T4']],
  [PriceRank.Diamond, ['1080Ti', '2080Ti', '3060', '3090', 'Tesla T4']],
])
export const rankGPUPriceMap = new Map([
  [PriceRank.Ordinary, ['5元/时/卡']],
  [PriceRank.Silver, ['4元/时/卡']],
  [PriceRank.Gold, ['3.2元/时/卡']],
  [PriceRank.Platinum, ['3元/时/卡']],
  [PriceRank.Diamond, ['2.5元/时/卡']],
])
export const rankCPUPriceMap = new Map([
  [PriceRank.Ordinary, ['4', '5.4', '5.95', '9']],
  [PriceRank.Silver, ['3.2', '4.32', '4.76', '7.2']],
  [PriceRank.Gold, ['2.8', '3.78', '4.165', '6.3']],
  [PriceRank.Platinum, ['2.4', '3.24', '3.57', '5.4']],
  [PriceRank.Diamond, ['2', '2.70', '2.975', '4.5']],
])
export const rankCPUOriginalPriceMap = new Map([
  [PriceRank.Ordinary, ['  ', '6元/时', '7元/时']],
  [PriceRank.Silver, ['  ', '4.8元/时', '5.6元/时']],
  [PriceRank.Gold, ['  ', '4.2元/时', '4.9元/时']],
  [PriceRank.Platinum, ['  ', '3.6元/时', '4.2元/时']],
  [PriceRank.Diamond, ['  ', '3元/时', '3.5元/时']],
])
export const rankCorePriceMap = new Map([
  [PriceRank.Ordinary, '¥0.25/核时'],
  [PriceRank.Silver, '¥0.20/核时'],
  [PriceRank.Gold, '¥0.175/核时'],
  [PriceRank.Platinum, '¥0.15/核时'],
  [PriceRank.Diamond, '¥0.125/核时'],
])
export const rankCoreNumMap = new Map([
  [PriceRank.Ordinary, ['20核', '24核', '28核', '36核']],
  [PriceRank.Silver, ['20核', '24核', '28核', '36核']],
  [PriceRank.Gold, ['20核', '24核', '28核', '36核']],
  [PriceRank.Platinum, ['20核', '24核', '28核', '36核']],
  [PriceRank.Diamond, ['20核', '24核', '28核', '36核']],
])

export const GPUPriceList = Object.values(PriceRank).map(v => ({
  icon: rankIconMap.get(v),
  title: rankTitleMap.get(v),
  priceList: priceListMap.get(v),
  trigger: rankTriggerMap.get(v),
  graphicsCard: rankGraphicsCardMap.get(v),
  price: rankGPUPriceMap.get(v),
  originalPrice: rankCPUOriginalPriceMap.get(v),
  bg: rankBgMap.get(v)[0],
  bgHover: rankBgMap.get(v)[1],
  bgMb: rankBgMap.get(v)[3],
  color: rankColorMap.get(v),
  lineStyle: rankLinMap.get(v),
}))
export const CPUPriceList = Object.values(PriceRank).map(v => ({
  icon: rankIconMap.get(v),
  title: rankTitleMap.get(v),
  trigger: rankTriggerMap.get(v),
  corePrice: rankCorePriceMap.get(v),
  coreNum: rankCoreNumMap.get(v),
  price: rankCPUPriceMap.get(v),
  originalPrice: rankCPUOriginalPriceMap.get(v),
  bg: rankBgMap.get(v)[0],
  bgHover: rankBgMap.get(v)[2],
  bgMb: rankBgMap.get(v)[3],
  color: rankColorMap.get(v),
  lineStyle: rankLinMap.get(v),
}))
