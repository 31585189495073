import { Flex } from '@renderbus/common/components'
import React from 'react'
import {
  Highlight,
  Littlelight,
  Light,
  Line,
  RenderPriceContainer,
  PriceCard,
  CoreSubWrapper,
  CellTitle,
  CoreContent,
  RechargeCell,
  NuclearPrice,
  ContentSubTitle,
  CoreWrapper,
  BgImg,
  RechargeButton,
  Trigger,
} from './atom'

export default class PriceTableRow extends React.PureComponent {
  state = {
    isHover: false,
  }
  handleHover = () => {
    this.props.resetDefaultHover()
    this.setState({
      isHover: true,
    })
  }
  handleBlur = () => {
    this.setState({
      isHover: false,
    })
  }
  render() {
    const {
      title,
      priceList,
      trigger,
      price,
      coreNum,
      bg,
      bgHover,
      bgMb,
      lineStyle,
      color,
    } = this.props.price
    const { isCPU } = this.props
    const isActive = this.props.isActive || this.state.isHover !== this.props.defaultHover
    return (
      <>
        <RenderPriceContainer onMouseOver={this.handleHover} onMouseOut={this.handleBlur}>
          <BgImg src={bg} isActive={isActive} isCPU={isCPU} className='noHover' />
          <BgImg src={bgHover} isActive={isActive} isCPU={isCPU} className='hover' />
          <BgImg src={bgMb} className='mb' />
          <PriceCard isActive={isActive || this.state.isHover}>
            <CellTitle isActive={isActive} color={color} isCPU={isCPU}>
              {title}
            </CellTitle>
            <Line isActive={isActive} lineStyle={lineStyle} isCPU={isCPU} />
            <ContentSubTitle
              isActive={isActive}
              style={{ display: isCPU ? 'none' : 'block' }}
              color={color}
            >
              <Highlight>{priceList}</Highlight>
              <Light>&nbsp;元/时/卡&nbsp;</Light>
              <Littlelight>起</Littlelight>
            </ContentSubTitle>
            <NuclearPrice
              style={{ display: isCPU ? 'flex' : 'none' }}
              isActive={isActive}
              isCPU={isCPU}
            >
              {coreNum && (
                <CoreWrapper>
                  <CoreSubWrapper>
                    {coreNum.map((card, index) => (
                      <CoreContent key={index} isActive={isActive} isCPU={isCPU}>
                        {card}
                      </CoreContent>
                    ))}
                  </CoreSubWrapper>
                </CoreWrapper>
              )}
              <RechargeCell>
                <Flex direction='column' halign='flex-end' valign='flex-start'>
                  {price.map((card, index) => (
                    <CoreContent
                      key={index}
                      className='right'
                      isActive={isActive}
                      color={color}
                      isCPU={isCPU}
                    >
                      <span>{card}</span>
                      <span>元/时</span>
                    </CoreContent>
                  ))}
                </Flex>
              </RechargeCell>
            </NuclearPrice>
            <RechargeButton
              isCPU={isCPU}
              show={isActive}
              href='https://account.renderbus.com/center/user/topUp'
              id={isCPU ? 'cpu-ljcz' : 'gpu-ljcz'}
              rel='nofollow'
            >
              立即充值
            </RechargeButton>
            <Trigger isActive={isActive}>{trigger}</Trigger>
          </PriceCard>
        </RenderPriceContainer>
      </>
    )
  }
}
