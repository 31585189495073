export const CPU_DATA = [
  { Processor: 'Intel Xeon W-1290P ', Score: '3243', GHz: '3.70' },
  { Processor: 'Intel Xeon W-1270P ', Score: '3108', GHz: '3.80' },
  { Processor: 'Intel Xeon W-1290 ', Score: '3075', GHz: '3.20' },
  { Processor: 'Intel Xeon W-1270 ', Score: '3071', GHz: '3.40' },
  { Processor: 'Intel Xeon E-2278G ', Score: '2991', GHz: '3.40' },
  { Processor: 'Intel Xeon W-10885M ', Score: '2978', GHz: '2.40' },
  { Processor: 'Intel Xeon W-1290T ', Score: '2974', GHz: '1.90' },
  { Processor: 'Intel Xeon W-1290E ', Score: '2923', GHz: '3.50' },
  { Processor: 'Intel Xeon W-1250 ', Score: '2914', GHz: '3.30' },
  { Processor: 'Intel Xeon E-2288G ', Score: '2893', GHz: '3.70' },
  { Processor: 'Intel Xeon E-2276G ', Score: '2890', GHz: '3.80' },
  { Processor: 'Intel Xeon E-2234 ', Score: '2886', GHz: '3.60' },
  { Processor: 'Intel Xeon E-2236 ', Score: '2869', GHz: '3.40' },
  { Processor: 'Intel Xeon E-2226G ', Score: '2865', GHz: '3.40' },
  { Processor: 'Intel Xeon E-2286G ', Score: '2858', GHz: '4.00' },
  { Processor: 'Intel Xeon W-2265 ', Score: '2853', GHz: '3.50' },
  { Processor: 'Intel Xeon E-2246G ', Score: '2846', GHz: '3.60' },
  { Processor: 'Intel Xeon E-2244G ', Score: '2833', GHz: '3.80' },
  { Processor: 'Intel Xeon E-2186G ', Score: '2824', GHz: '3.80' },
  { Processor: 'Intel Xeon W-10855M ', Score: '2811', GHz: '2.80' },
  { Processor: 'Intel Xeon E-2124G ', Score: '2801', GHz: '3.40' },
  { Processor: 'Intel Xeon E-2176G ', Score: '2797', GHz: '3.70' },
  { Processor: 'Intel Xeon W-2245 ', Score: '2765', GHz: '3.90' },
  { Processor: 'Intel Xeon E-2278GE ', Score: '2754', GHz: '3.30' },
  { Processor: 'Intel Xeon E-2274G ', Score: '2747', GHz: '4.00' },
  { Processor: 'Intel Xeon W-3275M ', Score: '2741', GHz: '2.50' },
  { Processor: 'Intel Xeon E-2224G ', Score: '2714', GHz: '3.50' },
  { Processor: 'Intel Xeon E-2136 ', Score: '2713', GHz: '3.30' },
  { Processor: 'Intel Xeon W-2225 ', Score: '2699', GHz: '4.10' },
  { Processor: 'Intel Xeon E-2146G ', Score: '2698', GHz: '3.50' },
  { Processor: 'Intel Xeon W-2295 ', Score: '2693', GHz: '3.00' },
  { Processor: 'Intel Xeon E3-1285 v6 ', Score: '2692', GHz: '4.10' },
  { Processor: 'Intel Xeon E-2144G ', Score: '2680', GHz: '3.60' },
  { Processor: 'Intel Xeon W-2235 ', Score: '2674', GHz: '3.80' },
  { Processor: 'Intel Xeon W-2155 ', Score: '2666', GHz: '3.30' },
  { Processor: 'Intel Xeon E-2126G ', Score: '2658', GHz: '3.30' },
  { Processor: 'Intel Xeon W-2125 ', Score: '2651', GHz: '4.00' },
  { Processor: 'Intel Xeon W-2150B ', Score: '2651', GHz: '3.00' },
  { Processor: 'Intel Xeon W-3245 ', Score: '2636', GHz: '3.20' },
  { Processor: 'Intel Xeon E-2224 ', Score: '2630', GHz: '3.40' },
  { Processor: 'Intel Xeon W-2145 ', Score: '2629', GHz: '3.70' },
  { Processor: 'Intel Xeon W-2135 ', Score: '2603', GHz: '3.70' },
  { Processor: 'Intel Xeon E-2174G ', Score: '2590', GHz: '3.80' },
  { Processor: 'Intel Xeon E-2124 ', Score: '2576', GHz: '3.30' },
  { Processor: 'Intel Xeon E3-1275 v6 ', Score: '2576', GHz: '3.80' },
  { Processor: 'Intel Core i5-1140G7 ', Score: '2573', GHz: '1.10' },
  { Processor: 'Intel Xeon W-3265 ', Score: '2572', GHz: '2.70' },
  { Processor: 'Intel Xeon W-3235 ', Score: '2571', GHz: '3.30' },
  { Processor: 'Intel Xeon E3-1280 v6 ', Score: '2564', GHz: '3.90' },
  { Processor: 'Intel Xeon W-2170B ', Score: '2559', GHz: '2.50' },
  { Processor: 'Intel Xeon Gold 5317 ', Score: '2537', GHz: '3.00' },
  { Processor: 'Intel Xeon Gold 6144 ', Score: '2523', GHz: '3.50' },
  { Processor: 'Intel Xeon Gold 6137 ', Score: '2499', GHz: '3.90' },
  { Processor: 'Intel Xeon Gold 6146 ', Score: '2493', GHz: '3.20' },
  { Processor: 'Intel Xeon W-2140B ', Score: '2480', GHz: '3.20' },
  { Processor: 'Intel Xeon E3-1240 v6 ', Score: '2469', GHz: '3.70' },
  { Processor: 'Intel Xeon W-2175 ', Score: '2458', GHz: '2.50' },
  { Processor: 'Intel Xeon Gold 6246 ', Score: '2450', GHz: '3.30' },
  { Processor: 'Intel Xeon Gold 6242R ', Score: '2442', GHz: '3.10' },
  { Processor: 'Intel Xeon E-2134 ', Score: '2440', GHz: '3.50' },
  { Processor: 'Intel Xeon W-3223 ', Score: '2432', GHz: '3.50' },
  { Processor: 'Intel Xeon E3-1245 v6 ', Score: '2429', GHz: '3.70' },
  { Processor: 'Intel Xeon E-2278GEL ', Score: '2428', GHz: '2.00' },
  { Processor: 'Intel Xeon W-2195 ', Score: '2413', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1270 v6 ', Score: '2409', GHz: '3.80' },
  { Processor: 'Intel Xeon E3-1535M v6 ', Score: '2403', GHz: '3.10' },
  { Processor: 'Intel Xeon E3-1585 v5 ', Score: '2402', GHz: '3.50' },
  { Processor: 'Intel Xeon Gold 5220 ', Score: '2396', GHz: '2.20' },
  { Processor: 'Intel Xeon Platinum 8151 ', Score: '2393', GHz: '3.40' },
  { Processor: 'Intel Xeon E3-1286 v3 ', Score: '2391', GHz: '3.70' },
  { Processor: 'Intel Xeon E5-1680 v4 ', Score: '2381', GHz: '3.40' },
  { Processor: 'Intel Xeon Gold 6254 ', Score: '2381', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-2689 v4 ', Score: '2380', GHz: '3.10' },
  { Processor: 'Intel Xeon E3-1270L v4 ', Score: '2378', GHz: '3.00' },
  { Processor: 'Intel Xeon Gold 5222 ', Score: '2372', GHz: '3.80' },
  { Processor: 'Intel Xeon Gold 6246R ', Score: '2370', GHz: '3.40' },
  { Processor: 'Intel Xeon E5-1650 v4 ', Score: '2367', GHz: '3.60' },
  { Processor: 'Intel Xeon E3-1285L v4 ', Score: '2354', GHz: '3.40' },
  { Processor: 'Intel Xeon E3-1240 v5 ', Score: '2354', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-1630 v4 ', Score: '2346', GHz: '3.70' },
  { Processor: 'Intel Xeon E3-1275 v5 ', Score: '2344', GHz: '3.60' },
  { Processor: 'Intel Xeon E3-1280 v5 ', Score: '2343', GHz: '3.70' },
  { Processor: 'Intel Xeon Silver 4310 ', Score: '2327', GHz: '2.10' },
  { Processor: 'Intel Xeon Gold 6248 ', Score: '2326', GHz: '2.50' },
  { Processor: 'Intel Xeon Platinum 8260M ', Score: '2315', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1281 v3 ', Score: '2308', GHz: '3.70' },
  { Processor: 'Intel Xeon W-2133 ', Score: '2308', GHz: '3.60' },
  { Processor: 'Intel Xeon E3-1225 v6 ', Score: '2296', GHz: '3.30' },
  { Processor: 'Intel Xeon Gold 6210U ', Score: '2295', GHz: '2.50' },
  { Processor: 'Intel Xeon E3-1270 v5 ', Score: '2289', GHz: '3.60' },
  { Processor: 'Intel Xeon E3-1260L v5 ', Score: '2287', GHz: '2.90' },
  { Processor: 'Intel Xeon E3-1245 v5 ', Score: '2277', GHz: '3.50' },
  { Processor: 'Intel Xeon W-2223 ', Score: '2275', GHz: '3.60' },
  { Processor: 'Intel Xeon E3-1271 v3 ', Score: '2271', GHz: '3.60' },
  { Processor: 'Intel Xeon Gold 6248R ', Score: '2270', GHz: '3.00' },
  { Processor: 'Intel Xeon E3-1230 v6 ', Score: '2269', GHz: '3.50' },
  { Processor: 'Intel Xeon E3-1276 v3 ', Score: '2269', GHz: '3.60' },
  { Processor: 'Intel Xeon W-2123 ', Score: '2267', GHz: '3.60' },
  { Processor: 'Intel Xeon Gold 5218R ', Score: '2261', GHz: '2.10' },
  { Processor: 'Intel Xeon E3-1285 v4 ', Score: '2260', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-1620 v4 ', Score: '2260', GHz: '3.50' },
  { Processor: 'Intel Xeon E3-1285 v3 ', Score: '2253', GHz: '3.60' },
  { Processor: 'Intel Xeon E5-2643 v4 ', Score: '2252', GHz: '3.40' },
  { Processor: 'Intel Xeon Gold 6134 ', Score: '2251', GHz: '3.20' },
  { Processor: 'Intel Xeon Gold 6230 ', Score: '2250', GHz: '2.10' },
  { Processor: 'Intel Xeon Gold 6154 ', Score: '2249', GHz: '3.00' },
  { Processor: 'Intel Xeon Gold 6143 ', Score: '2248', GHz: '2.80' },
  { Processor: 'Intel Xeon Gold 6128 ', Score: '2247', GHz: '3.40' },
  { Processor: 'Intel Xeon Gold 6208U ', Score: '2247', GHz: '2.90' },
  { Processor: 'Intel Xeon Gold 5218 ', Score: '2245', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2667 v4 ', Score: '2236', GHz: '3.20' },
  { Processor: 'Intel Xeon E3-1280 v3 ', Score: '2229', GHz: '3.60' },
  { Processor: 'Intel Xeon E5-1660 v4 ', Score: '2228', GHz: '3.20' },
  { Processor: 'Intel Xeon Gold 6138 ', Score: '2224', GHz: '2.00' },
  { Processor: 'Intel Xeon E3-1275 v3 ', Score: '2220', GHz: '3.50' },
  { Processor: 'Intel Xeon Gold 6226R ', Score: '2217', GHz: '2.90' },
  { Processor: 'Intel Xeon E3-1286L v3 ', Score: '2207', GHz: '3.20' },
  { Processor: 'Intel Xeon Gold 6242 ', Score: '2196', GHz: '2.80' },
  { Processor: 'Intel Xeon E3-1290 V2 ', Score: '2191', GHz: '3.70' },
  { Processor: 'Intel Xeon Gold 6136 ', Score: '2191', GHz: '3.00' },
  { Processor: 'Intel Xeon E3-1505M v6 ', Score: '2185', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2697A v4 ', Score: '2184', GHz: '2.60' },
  { Processor: 'Intel Xeon E3-1585L v5 ', Score: '2179', GHz: '3.00' },
  { Processor: 'Intel Xeon Gold 6132 ', Score: '2177', GHz: '2.60' },
  { Processor: 'Intel Xeon E3-1246 v3 ', Score: '2175', GHz: '3.50' },
  { Processor: 'Intel Xeon E3-1245 v3 ', Score: '2167', GHz: '3.40' },
  { Processor: 'Intel Xeon Platinum 8268 ', Score: '2167', GHz: '2.90' },
  { Processor: 'Intel Xeon Platinum 8168 ', Score: '2157', GHz: '2.70' },
  { Processor: 'Intel Xeon Gold 6152 ', Score: '2155', GHz: '2.10' },
  { Processor: 'Intel Xeon E3-1241 v3 ', Score: '2153', GHz: '3.50' },
  { Processor: 'Intel Xeon E3-1280 V2 ', Score: '2153', GHz: '3.60' },
  { Processor: 'Intel Xeon E3-1515M v5 ', Score: '2151', GHz: '2.80' },
  { Processor: 'Intel Xeon E3-1231 v3 ', Score: '2145', GHz: '3.40' },
  { Processor: 'Intel Xeon E3-1270 v3 ', Score: '2143', GHz: '3.50' },
  { Processor: 'Intel Xeon Gold 6252 ', Score: '2143', GHz: '2.10' },
  { Processor: 'Intel Xeon E3-1575M v5 ', Score: '2142', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-1630 v3 ', Score: '2141', GHz: '3.70' },
  { Processor: 'Intel Xeon E3-1240 v3 ', Score: '2139', GHz: '3.40' },
  { Processor: 'Intel Xeon E3-1285L v3 ', Score: '2132', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-2687W v4 ', Score: '2130', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2690 v4 ', Score: '2123', GHz: '2.60' },
  { Processor: 'Intel Xeon Platinum 8173M ', Score: '2123', GHz: '2.00' },
  { Processor: 'Intel Xeon E3-1275 V2 ', Score: '2119', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-1650 v3 ', Score: '2110', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-1680 v3 ', Score: '2107', GHz: '3.20' },
  { Processor: 'Intel Xeon Gold 6226 ', Score: '2104', GHz: '2.70' },
  { Processor: 'Intel Xeon E5-1660 v2 ', Score: '2104', GHz: '3.70' },
  { Processor: 'Intel Xeon E3-1535M v5 ', Score: '2104', GHz: '2.90' },
  { Processor: 'Intel Xeon E5-2637 v3 ', Score: '2103', GHz: '3.50' },
  { Processor: 'Intel Xeon Gold 5122 ', Score: '2098', GHz: '3.60' },
  { Processor: 'Intel Xeon Silver 4214R ', Score: '2095', GHz: '2.40' },
  { Processor: 'Intel Xeon E3-1270 V2 ', Score: '2089', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-2643 v3 ', Score: '2087', GHz: '3.40' },
  { Processor: 'Intel Xeon Platinum 8124M ', Score: '2084', GHz: '3.00' },
  { Processor: 'Intel Xeon E3-1230 v3 ', Score: '2078', GHz: '3.30' },
  { Processor: 'Intel Xeon E3-1226 v3 ', Score: '2076', GHz: '3.30' },
  { Processor: 'Intel Xeon E3-1230 v5 ', Score: '2073', GHz: '3.40' },
  { Processor: 'Intel Xeon E3-1220 v6 ', Score: '2072', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-1680 v2 ', Score: '2069', GHz: '3.00' },
  { Processor: 'Intel Xeon E3-1265L v3 ', Score: '2069', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2698 v4 ', Score: '2066', GHz: '2.20' },
  { Processor: 'Intel Xeon E3-1275L v3 ', Score: '2065', GHz: '2.70' },
  { Processor: 'Intel Xeon Silver 4215 ', Score: '2060', GHz: '2.50' },
  { Processor: 'Intel Xeon Gold 6148 ', Score: '2056', GHz: '2.40' },
  { Processor: 'Intel Xeon E3-1545M v5 ', Score: '2053', GHz: '2.90' },
  { Processor: 'Intel Xeon E5-2667 v3 ', Score: '2053', GHz: '3.20' },
  { Processor: 'Intel Xeon E5-2637 v4 ', Score: '2051', GHz: '3.50' },
  { Processor: 'Intel Xeon Gold 6138T ', Score: '2050', GHz: '2.00' },
  { Processor: 'Intel Xeon Platinum 8176 ', Score: '2048', GHz: '2.10' },
  { Processor: 'Intel Xeon E3-1240 V2 ', Score: '2040', GHz: '3.40' },
  { Processor: 'Intel Xeon E5-2663 v3 ', Score: '2040', GHz: '2.80' },
  { Processor: 'Intel Xeon E5-1681 v3 ', Score: '2040', GHz: '2.90' },
  { Processor: 'Intel Xeon E3-1220 v5 ', Score: '2035', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2687W v2 ', Score: '2031', GHz: '3.40' },
  { Processor: 'Intel Xeon E5-4627 v4 ', Score: '2030', GHz: '2.60' },
  { Processor: 'Intel Xeon E-2254ML ', Score: '2029', GHz: '1.70' },
  { Processor: 'Intel Xeon E3-1225 v3 ', Score: '2027', GHz: '3.20' },
  { Processor: 'Intel Xeon E5-1620 v3 ', Score: '2026', GHz: '3.50' },
  { Processor: 'Intel Xeon Gold 5215 ', Score: '2021', GHz: '2.50' },
  { Processor: 'Intel Xeon E3-1505M v5 ', Score: '2019', GHz: '2.80' },
  { Processor: 'Intel Xeon Gold 6126 ', Score: '2019', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2667 v2 ', Score: '2014', GHz: '3.30' },
  { Processor: 'Intel Xeon Gold 6212U ', Score: '2013', GHz: '2.40' },
  { Processor: 'Intel Xeon Platinum 8280 ', Score: '2011', GHz: '2.70' },
  { Processor: 'Intel Xeon E5-2697R v4 ', Score: '2007', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2623 v3 ', Score: '2006', GHz: '3.00' },
  { Processor: 'Intel Xeon E3-1245 V2 ', Score: '2002', GHz: '3.40' },
  { Processor: 'Intel Xeon E3-1230 V2 ', Score: '1994', GHz: '3.30' },
  { Processor: 'Intel Xeon E3-1225 v5 ', Score: '1988', GHz: '3.30' },
  { Processor: 'Intel Xeon Platinum 8160 ', Score: '1984', GHz: '2.10' },
  { Processor: 'Intel Xeon E-2104G ', Score: '1981', GHz: '3.20' },
  { Processor: 'Intel Xeon E5-1650 v2 ', Score: '1979', GHz: '3.50' },
  { Processor: 'Intel Xeon E3-1265L v4 ', Score: '1977', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1220 v3 ', Score: '1974', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-1620 v2 ', Score: '1974', GHz: '3.70' },
  { Processor: 'Intel Xeon E5-2698 v3 ', Score: '1971', GHz: '2.30' },
  { Processor: 'Intel Xeon Silver 4123 ', Score: '1967', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2673 v2 ', Score: '1959', GHz: '3.30' },
  { Processor: 'Intel Xeon E5-1607 v4 ', Score: '1951', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-2696 v4 ', Score: '1950', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2697 v3 ', Score: '1942', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2680 v4 ', Score: '1938', GHz: '2.40' },
  { Processor: 'Intel Xeon E3-1225 V2 ', Score: '1938', GHz: '3.20' },
  { Processor: 'Intel Xeon E5-2687W v3 ', Score: '1929', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-2679 v4 ', Score: '1928', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2696 v3 ', Score: '1927', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2699 v3 ', Score: '1927', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1220 V2 ', Score: '1926', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-2695 v4 ', Score: '1922', GHz: '2.10' },
  { Processor: 'Intel Xeon X5698 ', Score: '1922', GHz: '4.40' },
  { Processor: 'Intel Xeon E3-1240L v5 ', Score: '1921', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2673 v4 ', Score: '1909', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2699 v4 ', Score: '1903', GHz: '2.20' },
  { Processor: 'Intel Xeon Platinum 8175M ', Score: '1903', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2637 v2 ', Score: '1899', GHz: '3.50' },
  { Processor: 'Intel Xeon E3-1268L v3 ', Score: '1896', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-1660 v3 ', Score: '1887', GHz: '3.00' },
  { Processor: 'Intel Xeon Gold 5120 ', Score: '1887', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2690 v3 ', Score: '1886', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2618L v4 ', Score: '1879', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2690 v2 ', Score: '1877', GHz: '3.00' },
  { Processor: 'Intel Xeon D-1622 ', Score: '1875', GHz: '2.60' },
  { Processor: 'Intel Xeon E3-1205 v6 ', Score: '1875', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2618L v3 ', Score: '1872', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1268L v5 ', Score: '1869', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2640 v3 ', Score: '1867', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2682 v4 ', Score: '1865', GHz: '2.50' },
  { Processor: 'Intel Xeon Platinum 8259CL ', Score: '1863', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2686 v3 ', Score: '1862', GHz: '2.00' },
  { Processor: 'Intel Xeon Gold 6130 ', Score: '1859', GHz: '2.10' },
  { Processor: 'Intel Xeon Silver 4210R ', Score: '1858', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2643 v2 ', Score: '1857', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-2697 v4 ', Score: '1849', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1505L v6 ', Score: '1848', GHz: '2.20' },
  { Processor: 'Intel Xeon Silver 4210 ', Score: '1845', GHz: '2.20' },
  { Processor: 'Intel Xeon W-2104 ', Score: '1841', GHz: '3.20' },
  { Processor: 'Intel Xeon Gold 6140 ', Score: '1840', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2680R v4 ', Score: '1833', GHz: '2.40' },
  { Processor: 'Intel Xeon E3-1290 ', Score: '1833', GHz: '3.60' },
  { Processor: 'Intel Xeon Gold 5118 ', Score: '1832', GHz: '2.30' },
  { Processor: 'Intel Xeon Gold 5120T ', Score: '1827', GHz: '2.20' },
  { Processor: 'Intel Xeon Silver 4208 ', Score: '1818', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-4655 v3 ', Score: '1817', GHz: '2.90' },
  { Processor: 'Intel Xeon D-2143IT ', Score: '1812', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-1607 v3 ', Score: '1811', GHz: '3.10' },
  { Processor: 'Intel Pentium Gold G5400T ', Score: '1807', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-2630 v4 ', Score: '1795', GHz: '2.20' },
  { Processor: 'Intel Xeon Silver 4214Y ', Score: '1790', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2680 v2 ', Score: '1788', GHz: '2.80' },
  { Processor: 'Intel Xeon E5-1660 ', Score: '1787', GHz: '3.30' },
  { Processor: 'Intel Xeon E5-4627 v2 ', Score: '1786', GHz: '3.30' },
  { Processor: 'Intel Xeon Silver 4116T ', Score: '1781', GHz: '2.10' },
  { Processor: 'Intel Xeon E3-1280 ', Score: '1780', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-2695 v3 ', Score: '1778', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-1620 ', Score: '1776', GHz: '3.60' },
  { Processor: 'Intel Xeon E5-2680 v3 ', Score: '1770', GHz: '2.50' },
  { Processor: 'Intel Xeon W-2102 ', Score: '1764', GHz: '2.90' },
  { Processor: 'Intel Xeon E3-1235L v5 ', Score: '1761', GHz: '2.00' },
  { Processor: 'Intel Xeon E3-1275 ', Score: '1760', GHz: '3.40' },
  { Processor: 'Intel Pentium D1508 ', Score: '1760', GHz: '2.20' },
  { Processor: 'Intel Xeon E3-1270 ', Score: '1757', GHz: '3.40' },
  { Processor: 'Intel Xeon D-2141I ', Score: '1756', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-1603 v4 ', Score: '1754', GHz: '2.80' },
  { Processor: 'Intel Xeon E5-2660 v3 ', Score: '1753', GHz: '2.60' },
  { Processor: 'Intel Xeon D-2166NT ', Score: '1751', GHz: '2.00' },
  { Processor: 'Intel Xeon E5-2630 v3 ', Score: '1751', GHz: '2.40' },
  { Processor: 'Intel Xeon D-2123IT ', Score: '1750', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-4627 v3 ', Score: '1749', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2630L v4 ', Score: '1743', GHz: '1.80' },
  { Processor: 'Intel Xeon E5-1650 ', Score: '1739', GHz: '3.20' },
  { Processor: 'Intel Xeon E3-1245 ', Score: '1739', GHz: '3.30' },
  { Processor: 'Intel Xeon D-2146NT ', Score: '1735', GHz: '2.30' },
  { Processor: 'Intel Xeon D-1531 ', Score: '1731', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2676 v4 ', Score: '1730', GHz: '2.40' },
  { Processor: 'Intel Xeon Silver 4112 ', Score: '1729', GHz: '2.60' },
  { Processor: 'Intel Xeon D-2183IT ', Score: '1728', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2676 v3 ', Score: '1727', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2685 v3 ', Score: '1726', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2697 v2 ', Score: '1720', GHz: '2.70' },
  { Processor: 'Intel Xeon E3-1240 ', Score: '1715', GHz: '3.30' },
  { Processor: 'Intel Xeon E5-2687W ', Score: '1715', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-2660 v4 ', Score: '1713', GHz: '2.00' },
  { Processor: 'Intel Xeon Silver 4214 ', Score: '1712', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2658 v3 ', Score: '1711', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2673 v3 ', Score: '1706', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2678 v3 ', Score: '1702', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2683 v4 ', Score: '1702', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2623 v4 ', Score: '1700', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2640 v4 ', Score: '1700', GHz: '2.40' },
  { Processor: 'Intel Xeon Gold 6130T ', Score: '1698', GHz: '2.10' },
  { Processor: 'Intel Xeon E3-1240L v3 ', Score: '1695', GHz: '2.00' },
  { Processor: 'Intel Xeon D-1521 ', Score: '1693', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2620 v3 ', Score: '1691', GHz: '2.40' },
  { Processor: 'Intel Xeon Gold 6238 ', Score: '1691', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-1410 v2 ', Score: '1689', GHz: '2.80' },
  { Processor: 'Intel Xeon E3-1220L V2 ', Score: '1688', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2650 v2 ', Score: '1678', GHz: '2.60' },
  { Processor: 'Intel Xeon Silver 4116 ', Score: '1678', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2690 ', Score: '1673', GHz: '2.90' },
  { Processor: 'Intel Xeon Silver 4216 ', Score: '1667', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2670 v3 ', Score: '1667', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1230 ', Score: '1664', GHz: '3.20' },
  { Processor: 'Intel Xeon E5-2696 v2 ', Score: '1663', GHz: '2.50' },
  { Processor: 'Intel Xeon Silver 4114 ', Score: '1658', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2649 v3 ', Score: '1654', GHz: '2.30' },
  { Processor: 'Intel Xeon D-1548 ', Score: '1647', GHz: '2.00' },
  { Processor: 'Intel Xeon D-1541 ', Score: '1642', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2643 ', Score: '1641', GHz: '3.30' },
  { Processor: 'Intel Xeon E5-2620 v4 ', Score: '1638', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-1603 v3 ', Score: '1637', GHz: '2.80' },
  { Processor: 'Intel Xeon E3-1505L v5 ', Score: '1635', GHz: '2.00' },
  { Processor: 'Intel Xeon Silver 4110 ', Score: '1634', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-1607 v2 ', Score: '1623', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2470 v2 ', Score: '1623', GHz: '2.40' },
  { Processor: 'Intel Xeon X5677 ', Score: '1613', GHz: '3.47' },
  { Processor: 'Intel Xeon E5-2686 v4 ', Score: '1613', GHz: '2.30' },
  { Processor: 'Intel Xeon X5687 ', Score: '1610', GHz: '3.60' },
  { Processor: 'Intel Xeon W3690 ', Score: '1600', GHz: '3.47' },
  { Processor: 'Intel Xeon X5690 ', Score: '1600', GHz: '3.47' },
  { Processor: 'Intel Xeon E5-2650 v3 ', Score: '1597', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2658 v4 ', Score: '1590', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2683 v3 ', Score: '1587', GHz: '2.00' },
  { Processor: 'Intel Xeon X5672 ', Score: '1574', GHz: '3.20' },
  { Processor: 'Intel Xeon W5590 ', Score: '1571', GHz: '3.33' },
  { Processor: 'Intel Xeon E5-2692 v2 ', Score: '1571', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2680 ', Score: '1570', GHz: '2.70' },
  { Processor: 'Intel Xeon E5-2630 v2 ', Score: '1569', GHz: '2.60' },
  { Processor: 'Intel Xeon E3-1220 ', Score: '1563', GHz: '3.10' },
  { Processor: 'Intel Xeon E3-1235 ', Score: '1562', GHz: '3.20' },
  { Processor: 'Intel Xeon E3-1225 ', Score: '1560', GHz: '3.10' },
  { Processor: 'Intel Xeon E5-4660 v3 ', Score: '1558', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2689 ', Score: '1557', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2670 v2 ', Score: '1556', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2667 ', Score: '1554', GHz: '2.90' },
  { Processor: 'Intel Xeon E5-4617 ', Score: '1552', GHz: '2.90' },
  { Processor: 'Intel Xeon X5679 ', Score: '1547', GHz: '3.20' },
  { Processor: 'Intel Xeon W3580 ', Score: '1546', GHz: '3.33' },
  { Processor: 'Intel Xeon E5-2650L v4 ', Score: '1541', GHz: '1.70' },
  { Processor: 'Intel Xeon Silver 4108 ', Score: '1535', GHz: '1.80' },
  { Processor: 'Intel Xeon E5-2695 v2 ', Score: '1535', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2630L v3 ', Score: '1532', GHz: '1.80' },
  { Processor: 'Intel Xeon Gold 5117 ', Score: '1531', GHz: '2.00' },
  { Processor: 'Intel Xeon E5-2637 ', Score: '1526', GHz: '3.00' },
  { Processor: 'Intel Xeon W3570 ', Score: '1520', GHz: '3.20' },
  { Processor: 'Intel Xeon W3680 ', Score: '1519', GHz: '3.33' },
  { Processor: 'Intel Xeon E5-4640 v3 ', Score: '1514', GHz: '1.90' },
  { Processor: 'Intel Xeon E5-2660 v2 ', Score: '1510', GHz: '2.20' },
  { Processor: 'Intel Xeon W5580 ', Score: '1503', GHz: '3.20' },
  { Processor: 'Intel Xeon E3-1230L v3 ', Score: '1502', GHz: '1.80' },
  { Processor: 'Intel Xeon E5-2669 v3 ', Score: '1502', GHz: '2.30' },
  { Processor: 'Intel Xeon W3565 ', Score: '1498', GHz: '3.20' },
  { Processor: 'Intel Xeon X5270 ', Score: '1485', GHz: '3.50' },
  { Processor: 'Intel Xeon E5-1410 ', Score: '1484', GHz: '2.80' },
  { Processor: 'Intel Xeon E5-2628L v3 ', Score: '1483', GHz: '2.00' },
  { Processor: 'Intel Xeon E5-4650 ', Score: '1482', GHz: '2.70' },
  { Processor: 'Intel Xeon W3670 ', Score: '1479', GHz: '3.20' },
  { Processor: 'Intel Xeon E5-4657L v2 ', Score: '1478', GHz: '2.40' },
  { Processor: 'Intel Xeon X5680 ', Score: '1474', GHz: '3.33' },
  { Processor: 'Intel Xeon X5675 ', Score: '1472', GHz: '3.07' },
  { Processor: 'Intel Xeon X3480 ', Score: '1472', GHz: '3.07' },
  { Processor: 'Intel Xeon E5-2670 ', Score: '1471', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-4667 v3 ', Score: '1461', GHz: '2.00' },
  { Processor: 'Intel Xeon E5-4650L ', Score: '1457', GHz: '2.60' },
  { Processor: 'Intel Xeon Platinum 8167M ', Score: '1455', GHz: '2.00' },
  { Processor: 'Intel Xeon X5667 ', Score: '1452', GHz: '3.07' },
  { Processor: 'Intel Xeon E3-1260L ', Score: '1452', GHz: '2.40' },
  { Processor: 'Intel Xeon E3-1265L ', Score: '1451', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2629 v3 ', Score: '1451', GHz: '2.40' },
  { Processor: 'Intel Xeon X5470 ', Score: '1448', GHz: '3.33' },
  { Processor: 'Intel Xeon X5492 ', Score: '1446', GHz: '3.40' },
  { Processor: 'Intel Xeon Silver 4109T ', Score: '1445', GHz: '2.00' },
  { Processor: 'Intel Xeon E5-2470 ', Score: '1442', GHz: '2.30' },
  { Processor: 'Intel Xeon E3-1265L V2 ', Score: '1442', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-1607 ', Score: '1437', GHz: '3.00' },
  { Processor: 'Intel Xeon D-1602 ', Score: '1435', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2648L v4 ', Score: '1435', GHz: '1.80' },
  { Processor: 'Intel Xeon E5-2628L v4 ', Score: '1433', GHz: '1.90' },
  { Processor: 'Intel Xeon E5-4669 v3 ', Score: '1433', GHz: '2.10' },
  { Processor: 'Intel Xeon Gold 6133 ', Score: '1429', GHz: '2.50' },
  { Processor: 'Intel Xeon D-2187NT ', Score: '1425', GHz: '2.00' },
  { Processor: 'Intel Xeon E3-1220L ', Score: '1421', GHz: '2.20' },
  { Processor: 'Intel Xeon W3550 ', Score: '1420', GHz: '3.07' },
  { Processor: 'Intel Xeon X5570 ', Score: '1418', GHz: '2.93' },
  { Processor: 'Intel Xeon X3470 ', Score: '1414', GHz: '2.93' },
  { Processor: 'Intel Xeon E5-2665 ', Score: '1411', GHz: '2.40' },
  { Processor: 'Intel Xeon X5272 ', Score: '1411', GHz: '3.40' },
  { Processor: 'Intel Xeon X5260 ', Score: '1406', GHz: '3.33' },
  { Processor: 'Intel Xeon X5670 ', Score: '1406', GHz: '2.93' },
  { Processor: 'Intel Xeon X5660 ', Score: '1404', GHz: '2.80' },
  { Processor: 'Intel Xeon E5-2660 ', Score: '1403', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-4610 ', Score: '1401', GHz: '2.40' },
  { Processor: 'Intel Xeon X5482 ', Score: '1394', GHz: '3.20' },
  { Processor: 'Intel Pentium 1403 v2 ', Score: '1391', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-4650 v3 ', Score: '1384', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2420 v2 ', Score: '1382', GHz: '2.20' },
  { Processor: 'Intel Xeon X5460 ', Score: '1376', GHz: '3.16' },
  { Processor: 'Intel Xeon E5-2609 v2 ', Score: '1376', GHz: '2.50' },
  { Processor: 'Intel Xeon X5560 ', Score: '1374', GHz: '2.80' },
  { Processor: 'Intel Xeon X5647 ', Score: '1368', GHz: '2.93' },
  { Processor: 'Intel Xeon E5-4669 v4 ', Score: '1366', GHz: '2.20' },
  { Processor: 'Intel Xeon E3120 ', Score: '1364', GHz: '3.16' },
  { Processor: 'Intel Xeon E5-4607 v2 ', Score: '1363', GHz: '2.60' },
  { Processor: 'Intel Xeon E5-2650L v3 ', Score: '1356', GHz: '1.80' },
  { Processor: 'Intel Xeon E5-2430 v2 ', Score: '1354', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2648L v2 ', Score: '1350', GHz: '1.90' },
  { Processor: 'Intel Xeon E5-2640 ', Score: '1349', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-1603 ', Score: '1346', GHz: '2.80' },
  { Processor: 'Intel Xeon E5-2630L v2 ', Score: '1340', GHz: '2.40' },
  { Processor: 'Intel Xeon X3380 ', Score: '1335', GHz: '3.16' },
  { Processor: 'Intel Xeon E3110 ', Score: '1331', GHz: '3.00' },
  { Processor: 'Intel Xeon W3530 ', Score: '1327', GHz: '2.80' },
  { Processor: 'Intel Xeon E5-1428L v2 ', Score: '1321', GHz: '2.20' },
  { Processor: 'Intel Xeon D-1577 ', Score: '1319', GHz: '1.30' },
  { Processor: 'Intel Xeon E5-4648 v3 ', Score: '1317', GHz: '1.70' },
  { Processor: 'Intel Xeon E5240 ', Score: '1312', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-4640 ', Score: '1311', GHz: '2.40' },
  { Processor: 'Intel Xeon X3370 ', Score: '1311', GHz: '3.00' },
  { Processor: 'Intel Xeon X5650 ', Score: '1307', GHz: '2.67' },
  { Processor: 'Intel Xeon E5-2630 ', Score: '1302', GHz: '2.30' },
  { Processor: 'Intel Xeon E5-2407 v2 ', Score: '1302', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2658 v2 ', Score: '1301', GHz: '2.40' },
  { Processor: 'Intel Xeon L5240 ', Score: '1299', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2440 ', Score: '1299', GHz: '2.40' },
  { Processor: 'Intel Xeon X5550 ', Score: '1298', GHz: '2.67' },
  { Processor: 'Intel Xeon E5472 ', Score: '1297', GHz: '3.00' },
  { Processor: 'Intel Xeon D-1528 ', Score: '1296', GHz: '1.90' },
  { Processor: 'Intel Xeon E5-2620 v2 ', Score: '1292', GHz: '2.10' },
  { Processor: 'Intel Xeon E5-2648L v3 ', Score: '1292', GHz: '1.80' },
  { Processor: 'Intel Xeon W3540 ', Score: '1289', GHz: '2.93' },
  { Processor: 'Intel Xeon E5450 ', Score: '1288', GHz: '3.00' },
  { Processor: 'Intel Xeon E3113 ', Score: '1282', GHz: '3.00' },
  { Processor: 'Intel Xeon L3110 ', Score: '1279', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2640 v2 ', Score: '1274', GHz: '2.00' },
  { Processor: 'Intel Xeon X5472 ', Score: '1273', GHz: '3.00' },
  { Processor: 'Intel Xeon X5450 ', Score: '1265', GHz: '3.00' },
  { Processor: 'Intel Xeon D-1518 ', Score: '1256', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2650 ', Score: '1248', GHz: '2.00' },
  { Processor: 'Intel Xeon W3520 ', Score: '1243', GHz: '2.67' },
  { Processor: 'Intel Xeon X3360 ', Score: '1243', GHz: '2.83' },
  { Processor: 'Intel Xeon E5-4620 ', Score: '1239', GHz: '2.20' },
  { Processor: 'Intel Xeon X3363 ', Score: '1237', GHz: '2.83' },
  { Processor: 'Intel Xeon E5-2448L v2 ', Score: '1235', GHz: '1.80' },
  { Processor: 'Intel Xeon E5440 ', Score: '1235', GHz: '2.83' },
  { Processor: 'Intel Xeon E5-2430 ', Score: '1234', GHz: '2.20' },
  { Processor: 'Intel Xeon L3360 ', Score: '1226', GHz: '2.83' },
  { Processor: 'Intel Xeon E5-2608L v3 ', Score: '1210', GHz: '2.00' },
  { Processor: 'Intel Xeon E5-2675 v3 ', Score: '1209', GHz: '1.80' },
  { Processor: 'Intel Xeon X5365 ', Score: '1207', GHz: '3.00' },
  { Processor: 'Intel Xeon X3460 ', Score: '1206', GHz: '2.80' },
  { Processor: 'Intel Xeon X3450 ', Score: '1206', GHz: '2.67' },
  { Processor: 'Intel Xeon E5640 ', Score: '1204', GHz: '2.67' },
  { Processor: 'Intel Xeon E5-2650 v4 ', Score: '1201', GHz: '2.20' },
  { Processor: 'Intel Xeon E5630 ', Score: '1188', GHz: '2.53' },
  { Processor: 'Intel Xeon D-1539 ', Score: '1179', GHz: '1.60' },
  { Processor: 'Intel Xeon E5649 ', Score: '1176', GHz: '2.53' },
  { Processor: 'Intel Xeon X3353 ', Score: '1173', GHz: '2.66' },
  { Processor: 'Intel Xeon L5530 ', Score: '1169', GHz: '2.40' },
  { Processor: 'Intel Xeon L5609 ', Score: '1160', GHz: '1.87' },
  { Processor: 'Intel Xeon X3330 ', Score: '1160', GHz: '2.66' },
  { Processor: 'Intel Xeon E5430 ', Score: '1156', GHz: '2.66' },
  { Processor: 'Intel Xeon X3350 ', Score: '1156', GHz: '2.66' },
  { Processor: 'Intel Xeon E5-2658 ', Score: '1156', GHz: '2.10' },
  { Processor: 'Intel Xeon E5530 ', Score: '1150', GHz: '2.40' },
  { Processor: 'Intel Xeon L3426 ', Score: '1149', GHz: '1.87' },
  { Processor: 'Intel Xeon E5540 ', Score: '1149', GHz: '2.53' },
  { Processor: 'Intel Xeon X3440 ', Score: '1149', GHz: '2.53' },
  { Processor: 'Intel Xeon L5430 ', Score: '1144', GHz: '2.66' },
  { Processor: 'Intel Xeon E5462 ', Score: '1142', GHz: '2.80' },
  { Processor: 'Intel Xeon E5645 ', Score: '1140', GHz: '2.40' },
  { Processor: 'Intel Xeon L5640 ', Score: '1139', GHz: '2.27' },
  { Processor: 'Intel Xeon E5-2450 ', Score: '1137', GHz: '2.10' },
  { Processor: 'Intel Xeon 5160 ', Score: '1134', GHz: '3.00' },
  { Processor: 'Intel Xeon 3085 ', Score: '1124', GHz: '3.00' },
  { Processor: 'Intel Xeon E5-2630L ', Score: '1119', GHz: '2.00' },
  { Processor: 'Intel Xeon E5620 ', Score: '1118', GHz: '2.40' },
  { Processor: 'Intel Xeon E5-2620 ', Score: '1111', GHz: '2.00' },
  { Processor: 'Intel Xeon E5-2420 ', Score: '1109', GHz: '1.90' },
  { Processor: 'Intel Xeon E5-2651 v2 ', Score: '1108', GHz: '1.80' },
  { Processor: 'Intel Xeon E5-2628L v2 ', Score: '1106', GHz: '1.90' },
  { Processor: 'Intel Xeon W3505 ', Score: '1105', GHz: '2.53' },
  { Processor: 'Intel Xeon D-1537 ', Score: '1104', GHz: '1.70' },
  { Processor: 'Intel Xeon L5639 ', Score: '1102', GHz: '2.13' },
  { Processor: 'Intel Xeon E5-2650L v2 ', Score: '1102', GHz: '1.70' },
  { Processor: 'Intel Xeon L5420 ', Score: '1102', GHz: '2.50' },
  { Processor: 'Intel Xeon X3320 ', Score: '1097', GHz: '2.50' },
  { Processor: 'Intel Xeon 3075 ', Score: '1096', GHz: '2.66' },
  { Processor: 'Intel Xeon E5-2609 v3 ', Score: '1095', GHz: '1.90' },
  { Processor: 'Intel Xeon Bronze 3204 ', Score: '1093', GHz: '1.90' },
  { Processor: 'Intel Xeon E5-2650L ', Score: '1091', GHz: '1.80' },
  { Processor: 'Intel Xeon X3230 ', Score: '1089', GHz: '2.66' },
  { Processor: 'Intel Xeon E5-2609 v4 ', Score: '1078', GHz: '1.70' },
  { Processor: 'Intel Xeon L5520 ', Score: '1067', GHz: '2.27' },
  { Processor: 'Intel Xeon E5420 ', Score: '1065', GHz: '2.50' },
  { Processor: 'Intel Xeon X5355 ', Score: '1060', GHz: '2.66' },
  { Processor: 'Intel Xeon E5-2609 ', Score: '1059', GHz: '2.40' },
  { Processor: 'Intel Xeon X3323 ', Score: '1059', GHz: '2.50' },
  { Processor: 'Intel Xeon E5-2603 v4 ', Score: '1046', GHz: '1.70' },
  { Processor: 'Intel Xeon E5-2699A v4 ', Score: '1037', GHz: '2.40' },
  { Processor: 'Intel Xeon E5520 ', Score: '1036', GHz: '2.27' },
  { Processor: 'Intel Xeon W3503 ', Score: '1032', GHz: '2.40' },
  { Processor: 'Intel Xeon L5630 ', Score: '1031', GHz: '2.13' },
  { Processor: 'Intel Xeon L5410 ', Score: '1027', GHz: '2.33' },
  { Processor: 'Intel Xeon E5-4610 v3 ', Score: '1027', GHz: '1.70' },
  { Processor: 'Intel Xeon 3070 ', Score: '1024', GHz: '2.66' },
  { Processor: 'Intel Xeon E5410 ', Score: '1024', GHz: '2.33' },
  { Processor: 'Intel Xeon ', Score: '1023', GHz: '2.20' },
  { Processor: 'Intel Xeon E5-2450L ', Score: '1013', GHz: '1.80' },
  { Processor: 'Intel Xeon E5-2430L ', Score: '1005', GHz: '2.00' },
  { Processor: 'Intel Xeon X3430 ', Score: '1002', GHz: '2.40' },
  { Processor: 'Intel Xeon E5607 ', Score: '1002', GHz: '2.27' },
  { Processor: 'Intel Core i9-11900KF ', Score: '3618', GHz: '3.50' },
  { Processor: 'Intel Core i9-11900F ', Score: '3590', GHz: '2.50' },
  { Processor: 'Intel Core i9-11900K ', Score: '3489', GHz: '3.50' },
  { Processor: 'Intel Core i7-11700K ', Score: '3468', GHz: '3.60' },
  { Processor: 'Intel Core i9-11900 ', Score: '3446', GHz: '2.50' },
  { Processor: 'Intel Core i5-11600KF ', Score: '3406', GHz: '3.90' },
  { Processor: 'Intel Core i7-11700F ', Score: '3392', GHz: '2.50' },
  { Processor: 'Intel Core i5-11600 ', Score: '3373', GHz: '2.80' },
  { Processor: 'Intel Core i5-11600K ', Score: '3363', GHz: '3.90' },
  { Processor: 'Intel Core i7-11700KF ', Score: '3336', GHz: '3.60' },
  { Processor: 'Intel Core i9-10900K ', Score: '3166', GHz: '3.70' },
  { Processor: 'Intel Core i9-10910 ', Score: '3138', GHz: '3.60' },
  { Processor: 'Intel Core i9-10900KF ', Score: '3132', GHz: '3.70' },
  { Processor: 'Intel Core i9-10850K ', Score: '3102', GHz: '3.60' },
  { Processor: 'Intel Core i7-10700K ', Score: '3085', GHz: '3.80' },
  { Processor: 'Intel Core i5-11400 ', Score: '3077', GHz: '2.60' },
  { Processor: 'Intel Core i9-10900F ', Score: '3072', GHz: '2.80' },
  { Processor: 'Intel Core i9-10900 ', Score: '3070', GHz: '2.80' },
  { Processor: 'Intel Core i5-11500 ', Score: '3068', GHz: '2.70' },
  { Processor: 'Intel Core i5-11400F ', Score: '3062', GHz: '2.60' },
  { Processor: 'Intel Core i7-10700KF ', Score: '3054', GHz: '3.80' },
  { Processor: 'Intel Core i9-9900KS ', Score: '3049', GHz: '4.00' },
  { Processor: 'Intel Core i9-9900KF ', Score: '2992', GHz: '3.60' },
  { Processor: 'Intel Core i9-9900K ', Score: '2973', GHz: '3.60' },
  { Processor: 'Intel Core i9-9990XE ', Score: '2970', GHz: '4.00' },
  { Processor: 'Intel Core i5-10600KF ', Score: '2955', GHz: '4.10' },
  { Processor: 'Intel Core i7-9700KF ', Score: '2946', GHz: '3.60' },
  { Processor: 'Intel Core i5-10600K ', Score: '2935', GHz: '4.10' },
  { Processor: 'Intel Core i7-10700 ', Score: '2934', GHz: '2.90' },
  { Processor: 'Intel Core i7-10700F ', Score: '2924', GHz: '2.90' },
  { Processor: 'Intel Core i7-8086K ', Score: '2917', GHz: '4.00' },
  { Processor: 'Intel Core i7-9700K ', Score: '2908', GHz: '3.60' },
  { Processor: 'Intel Core i9-9900 ', Score: '2894', GHz: '3.10' },
  { Processor: 'Intel Core i5-10600 ', Score: '2875', GHz: '3.30' },
  { Processor: 'Intel Xeon W-2275 ', Score: '2858', GHz: '3.30' },
  { Processor: 'Intel Core i3-9350K ', Score: '2847', GHz: '4.00' },
  { Processor: 'Intel Core i3-10320 ', Score: '2837', GHz: '3.80' },
  { Processor: 'Intel Core i7-9700F ', Score: '2816', GHz: '3.00' },
  { Processor: 'Intel Core i7-9700 ', Score: '2804', GHz: '3.00' },
  { Processor: 'Intel Core i7-7740X ', Score: '2801', GHz: '4.30' },
  { Processor: 'Intel Core i5-9600KF ', Score: '2798', GHz: '3.70' },
  { Processor: 'Intel Core i5-9600K ', Score: '2785', GHz: '3.70' },
  { Processor: 'Intel Core i7-8700K ', Score: '2782', GHz: '3.70' },
  { Processor: 'Intel Core i5-10500 ', Score: '2773', GHz: '3.10' },
  { Processor: 'Intel Xeon W-2255 ', Score: '2763', GHz: '3.70' },
  { Processor: 'Intel Core i7-7700K ', Score: '2752', GHz: '4.20' },
  { Processor: 'Intel Core i9-10920X ', Score: '2746', GHz: '3.50' },
  { Processor: 'Intel Core i9-10940X ', Score: '2738', GHz: '3.30' },
  { Processor: 'Intel Core i3-9350KF ', Score: '2736', GHz: '4.00' },
  { Processor: 'Intel Core i7-10700T ', Score: '2725', GHz: '2.00' },
  { Processor: 'Intel Core i3-10105 ', Score: '2720', GHz: '3.70' },
  { Processor: 'Intel Core i5-9600 ', Score: '2720', GHz: '3.10' },
  { Processor: 'Intel Core i9-10900X ', Score: '2717', GHz: '3.70' },
  { Processor: 'Intel Core i3-9320 ', Score: '2711', GHz: '3.70' },
  { Processor: 'Intel Core i5-9500F ', Score: '2690', GHz: '3.00' },
  { Processor: 'Intel Core i3-10105F ', Score: '2690', GHz: '3.70' },
  { Processor: 'Intel Core i3-10300 ', Score: '2680', GHz: '3.70' },
  { Processor: 'Intel Core i7-8700 ', Score: '2677', GHz: '3.20' },
  { Processor: 'Intel Core i3-9300 ', Score: '2666', GHz: '3.70' },
  { Processor: 'Intel Core i9-10980XE ', Score: '2664', GHz: '3.00' },
  { Processor: 'Intel Core i5-10300H ', Score: '2645', GHz: '2.50' },
  { Processor: 'Intel Core i3-10100 ', Score: '2639', GHz: '3.60' },
  { Processor: 'Intel Core i7-9700E ', Score: '2628', GHz: '2.60' },
  { Processor: 'Intel Core i7-9800X ', Score: '2626', GHz: '3.80' },
  { Processor: 'Intel Core i9-9960X ', Score: '2626', GHz: '3.10' },
  { Processor: 'Intel Core i9-9920X ', Score: '2624', GHz: '3.50' },
  { Processor: 'Intel Core i5-8600K ', Score: '2620', GHz: '3.60' },
  { Processor: 'Intel Core i9-9940X ', Score: '2617', GHz: '3.30' },
  { Processor: 'Intel Core i9-7900X ', Score: '2613', GHz: '3.30' },
  { Processor: 'Intel Core i3-10100F ', Score: '2612', GHz: '3.60' },
  { Processor: 'Intel Core i7-7900X ', Score: '2610', GHz: '3.30' },
  { Processor: 'Intel Core i5-9500 ', Score: '2605', GHz: '3.00' },
  { Processor: 'Intel Core i5-10400 ', Score: '2592', GHz: '2.90' },
  { Processor: 'Intel Core i9-9900X ', Score: '2591', GHz: '3.50' },
  { Processor: 'Intel Core i9-9980XE ', Score: '2588', GHz: '3.00' },
  { Processor: 'Intel Core i5-10400F ', Score: '2579', GHz: '2.90' },
  { Processor: 'Intel Core i9-7960X ', Score: '2572', GHz: '2.80' },
  { Processor: 'Intel Core i5-7600K ', Score: '2570', GHz: '3.80' },
  { Processor: 'Intel Pentium Gold G6500 ', Score: '2567', GHz: '4.10' },
  { Processor: 'Intel Core i5-8600 ', Score: '2567', GHz: '3.10' },
  { Processor: 'Intel Pentium Gold G6600 ', Score: '2564', GHz: '4.20' },
  { Processor: 'Intel Core i7-7820X ', Score: '2536', GHz: '3.60' },
  { Processor: 'Intel Core i7-6700K ', Score: '2529', GHz: '4.00' },
  { Processor: 'Intel Core i3-9100 ', Score: '2527', GHz: '3.60' },
  { Processor: 'Intel Core i3-9100F ', Score: '2526', GHz: '3.60' },
  { Processor: 'Intel Core i7-9700T ', Score: '2520', GHz: '2.00' },
  { Processor: 'Intel Core i9-10900T ', Score: '2515', GHz: '1.90' },
  { Processor: 'Intel Core i5-10600T ', Score: '2510', GHz: '2.40' },
  { Processor: 'Intel Core i3-8350K ', Score: '2508', GHz: '4.00' },
  { Processor: 'Intel Core i3-7350K ', Score: '2506', GHz: '4.20' },
  { Processor: 'Intel Core i9-7940X ', Score: '2501', GHz: '3.10' },
  { Processor: 'Intel Core i5-9400 ', Score: '2493', GHz: '2.90' },
  { Processor: 'Intel Core i5-8500 ', Score: '2491', GHz: '3.00' },
  { Processor: 'Intel Core i3-7300 ', Score: '2485', GHz: '4.00' },
  { Processor: 'Intel Core i9-9900T ', Score: '2484', GHz: '2.10' },
  { Processor: 'Intel Pentium Gold G6400 ', Score: '2483', GHz: '4.00' },
  { Processor: 'Intel Core i5-7600 ', Score: '2483', GHz: '3.50' },
  { Processor: 'Intel Core i5-9400F ', Score: '2483', GHz: '2.90' },
  { Processor: 'Intel Core i5-7640X ', Score: '2479', GHz: '4.00' },
  { Processor: 'Intel Core i7-7700 ', Score: '2478', GHz: '3.60' },
  { Processor: 'Intel Core i9-7920X ', Score: '2478', GHz: '2.90' },
  { Processor: 'Intel Core i9-7980XE ', Score: '2477', GHz: '2.60' },
  { Processor: 'Intel Core i7-4790K ', Score: '2469', GHz: '4.00' },
  { Processor: 'Intel Core i3-7320 ', Score: '2456', GHz: '4.10' },
  { Processor: 'Intel Pentium Gold G5600F ', Score: '2442', GHz: '3.90' },
  { Processor: 'Intel Core i9-9820X ', Score: '2431', GHz: '3.30' },
  { Processor: 'Intel Core i5-9600T ', Score: '2429', GHz: '2.30' },
  { Processor: 'Intel Core i7-7800X ', Score: '2425', GHz: '3.50' },
  { Processor: 'Intel Pentium Gold G5620 ', Score: '2424', GHz: '4.00' },
  { Processor: 'Intel Core i7-6850K ', Score: '2418', GHz: '3.60' },
  { Processor: 'Intel Core i5-8400 ', Score: '2413', GHz: '2.80' },
  { Processor: 'Intel Core i3-10300T ', Score: '2404', GHz: '3.00' },
  { Processor: 'Intel Core i3-6320 ', Score: '2388', GHz: '3.90' },
  { Processor: 'Intel Core i7-8700T ', Score: '2388', GHz: '2.40' },
  { Processor: 'Intel Core i7-6900K ', Score: '2385', GHz: '3.20' },
  { Processor: 'Intel Core i5-10500T ', Score: '2371', GHz: '2.30' },
  { Processor: 'Intel Core i7-6950X ', Score: '2356', GHz: '3.00' },
  { Processor: 'Intel Core i3-6300 ', Score: '2355', GHz: '3.80' },
  { Processor: 'Intel Core i5-6600K ', Score: '2333', GHz: '3.50' },
  { Processor: 'Intel Core i3-7100 ', Score: '2326', GHz: '3.90' },
  { Processor: 'Intel Core i7-11700 ', Score: '2321', GHz: '2.50' },
  { Processor: 'Intel Core i7-5775C ', Score: '2316', GHz: '3.30' },
  { Processor: 'Intel Core i3-9300T ', Score: '2312', GHz: '3.20' },
  { Processor: 'Intel Core i3-8300 ', Score: '2312', GHz: '3.70' },
  { Processor: 'Intel Core i7-9700TE ', Score: '2302', GHz: '1.80' },
  { Processor: 'Intel Core i7-6700 ', Score: '2302', GHz: '3.40' },
  { Processor: 'Intel Pentium Gold G5420 ', Score: '2295', GHz: '3.80' },
  { Processor: 'Intel Core i5-5675R ', Score: '2283', GHz: '3.10' },
  { Processor: 'Intel Pentium G4620 ', Score: '2280', GHz: '3.70' },
  { Processor: 'Intel Core i5-7500 ', Score: '2279', GHz: '3.40' },
  { Processor: 'Intel Core i5-6600 ', Score: '2278', GHz: '3.30' },
  { Processor: 'Intel Pentium Gold G6500T ', Score: '2277', GHz: '3.50' },
  { Processor: 'Intel Core i5-7600T ', Score: '2276', GHz: '2.80' },
  { Processor: 'Intel Pentium Gold G5600 ', Score: '2273', GHz: '3.90' },
  { Processor: 'Intel Core i7-7700T ', Score: '2264', GHz: '2.90' },
  { Processor: 'Intel Core i7-6800K ', Score: '2263', GHz: '3.40' },
  { Processor: 'Intel Core i5-8600T ', Score: '2250', GHz: '2.30' },
  { Processor: 'Intel Core i7-5675C ', Score: '2249', GHz: '3.10' },
  { Processor: 'Intel Core i3-8100 ', Score: '2241', GHz: '3.60' },
  { Processor: 'Intel Pentium G4520 ', Score: '2241', GHz: '3.60' },
  { Processor: 'Intel Pentium Gold G5500 ', Score: '2237', GHz: '3.80' },
  { Processor: 'Intel Core i5-5675C ', Score: '2232', GHz: '3.10' },
  { Processor: 'Intel Pentium Gold G5400 ', Score: '2231', GHz: '3.70' },
  { Processor: 'Intel Core i7-4790 ', Score: '2226', GHz: '3.60' },
  { Processor: 'Intel Core i3-6100 ', Score: '2226', GHz: '3.70' },
  { Processor: 'Intel Core i5-4690S ', Score: '2212', GHz: '3.20' },
  { Processor: 'Intel Celeron G5905 ', Score: '2210', GHz: '3.50' },
  { Processor: 'Intel Core i3-9100T ', Score: '2207', GHz: '3.10' },
  { Processor: 'Intel Core i7-4790S ', Score: '2207', GHz: '3.20' },
  { Processor: 'Intel Core i5-4690 ', Score: '2203', GHz: '3.50' },
  { Processor: 'Intel Core i7-4771 ', Score: '2201', GHz: '3.50' },
  { Processor: 'Intel Core i5-4690K ', Score: '2195', GHz: '3.50' },
  { Processor: 'Intel Core i5-9500T ', Score: '2191', GHz: '2.20' },
  { Processor: 'Intel Pentium G4600 ', Score: '2184', GHz: '3.60' },
  { Processor: 'Intel Core i7-4770 ', Score: '2171', GHz: '3.40' },
  { Processor: 'Intel Celeron G5920 ', Score: '2168', GHz: '3.50' },
  { Processor: 'Intel Core i5-4670R ', Score: '2164', GHz: '3.00' },
  { Processor: 'Intel Celeron G5900 ', Score: '2162', GHz: '3.40' },
  { Processor: 'Intel Pentium G4500 ', Score: '2156', GHz: '3.50' },
  { Processor: 'Intel Core i7-4770K ', Score: '2156', GHz: '3.50' },
  { Processor: 'Intel Core i5-10400T ', Score: '2154', GHz: '2.00' },
  { Processor: 'Intel Core i5-4670K ', Score: '2148', GHz: '3.40' },
  { Processor: 'Intel Pentium Gold G6400T ', Score: '2140', GHz: '3.40' },
  { Processor: 'Intel Core i7-9850HL ', Score: '2139', GHz: '1.90' },
  { Processor: 'Intel Core i7-4790T ', Score: '2133', GHz: '2.70' },
  { Processor: 'Intel Core i5-4670 ', Score: '2132', GHz: '3.40' },
  { Processor: 'Intel Core i7-4770R ', Score: '2132', GHz: '3.20' },
  { Processor: 'Intel Core i5-6600T ', Score: '2129', GHz: '2.70' },
  { Processor: 'Intel Core i3-7300T ', Score: '2128', GHz: '3.50' },
  { Processor: 'Intel Core i5-6500 ', Score: '2127', GHz: '3.20' },
  { Processor: 'Intel Xeon W-3175X ', Score: '2126', GHz: '3.10' },
  { Processor: 'Intel Core i7-5775R ', Score: '2126', GHz: '3.30' },
  { Processor: 'Intel Pentium G4560 ', Score: '2121', GHz: '3.50' },
  { Processor: 'Intel Core i7-6700TE ', Score: '2118', GHz: '2.40' },
  { Processor: 'Intel Core i7-6700T ', Score: '2117', GHz: '2.80' },
  { Processor: 'Intel Core i7-4770S ', Score: '2117', GHz: '3.10' },
  { Processor: 'Intel Core i3-7101TE ', Score: '2111', GHz: '3.40' },
  { Processor: 'Intel Core i5-7400 ', Score: '2109', GHz: '3.00' },
  { Processor: 'Intel Core i3-4170 ', Score: '2108', GHz: '3.70' },
  { Processor: 'Intel Pentium G3470 ', Score: '2089', GHz: '3.60' },
  { Processor: 'Intel Core i5-4670S ', Score: '2089', GHz: '3.10' },
  { Processor: 'Intel Core i3-6098P ', Score: '2089', GHz: '3.60' },
  { Processor: 'Intel Core i5-4590 ', Score: '2087', GHz: '3.30' },
  { Processor: 'Intel Core i5-8500T ', Score: '2086', GHz: '2.10' },
  { Processor: 'Intel Core i7-4960X ', Score: '2079', GHz: '3.60' },
  { Processor: 'Intel Core i5-4590S ', Score: '2072', GHz: '3.00' },
  { Processor: 'Intel Core i7-3770 ', Score: '2072', GHz: '3.40' },
  { Processor: 'Intel Core i7-3770K ', Score: '2069', GHz: '3.50' },
  { Processor: 'Intel Celeron G5905T ', Score: '2065', GHz: '3.30' },
  { Processor: 'Intel Core i7-5930K ', Score: '2062', GHz: '3.50' },
  { Processor: 'Intel Core i5-5575R ', Score: '2058', GHz: '2.80' },
  { Processor: 'Intel Core i5-6402P ', Score: '2056', GHz: '2.80' },
  { Processor: 'Intel Core i3-6300T ', Score: '2055', GHz: '3.30' },
  { Processor: 'Intel Core i3-7100T ', Score: '2053', GHz: '3.40' },
  { Processor: 'Intel Core i3-4360 ', Score: '2052', GHz: '3.70' },
  { Processor: 'Intel Core i5-9400T ', Score: '2050', GHz: '1.80' },
  { Processor: 'Intel Core i7-3770S ', Score: '2047', GHz: '3.10' },
  { Processor: 'Intel Core i7-4770T ', Score: '2046', GHz: '2.50' },
  { Processor: 'Intel Core i5-3570K ', Score: '2045', GHz: '3.40' },
  { Processor: 'Intel Core i5-4670K CPT ', Score: '2042', GHz: '3.40' },
  { Processor: 'Intel Celeron G4950 ', Score: '2041', GHz: '3.30' },
  { Processor: 'Intel Core i5-3570 ', Score: '2040', GHz: '3.40' },
  { Processor: 'Intel Core i3-4340 ', Score: '2039', GHz: '3.60' },
  { Processor: 'Intel Pentium G4400 ', Score: '2031', GHz: '3.30' },
  { Processor: 'Intel Core i5-4570 ', Score: '2027', GHz: '3.20' },
  { Processor: 'Intel Core i3-4370 ', Score: '2023', GHz: '3.80' },
  { Processor: 'Intel Core i7-5820K ', Score: '2013', GHz: '3.30' },
  { Processor: 'Intel Core i3-4160 ', Score: '2005', GHz: '3.60' },
  { Processor: 'Intel Core i5-3550 ', Score: '2005', GHz: '3.30' },
  { Processor: 'Intel Core i3-8300T ', Score: '2005', GHz: '3.20' },
  { Processor: 'Intel Celeron G4930 ', Score: '2004', GHz: '3.20' },
  { Processor: 'Intel Core i5-7500T ', Score: '1995', GHz: '2.70' },
  { Processor: 'Intel Core i3-4330 ', Score: '1991', GHz: '3.50' },
  { Processor: 'Intel Core i5-3570S ', Score: '1987', GHz: '3.10' },
  { Processor: 'Intel Core i7-5960X ', Score: '1985', GHz: '3.00' },
  { Processor: 'Intel Core i5-4570S ', Score: '1976', GHz: '2.90' },
  { Processor: 'Intel Core i5-6400 ', Score: '1973', GHz: '2.70' },
  { Processor: 'Intel Pentium Gold G5420T ', Score: '1972', GHz: '3.20' },
  { Processor: 'Intel Celeron G4920 ', Score: '1965', GHz: '3.20' },
  { Processor: 'Intel Pentium Gold G5500T ', Score: '1964', GHz: '3.20' },
  { Processor: 'Intel Core i5-3550S ', Score: '1963', GHz: '3.00' },
  { Processor: 'Intel Core i7-3770T ', Score: '1962', GHz: '2.50' },
  { Processor: 'Intel Core i7-4930K ', Score: '1960', GHz: '3.40' },
  { Processor: 'Intel Core i7-4820K ', Score: '1945', GHz: '3.70' },
  { Processor: 'Intel Core i5-4570T ', Score: '1942', GHz: '2.90' },
  { Processor: 'Intel Core i5-3470 ', Score: '1938', GHz: '3.20' },
  { Processor: 'Intel Core i5-8400T ', Score: '1931', GHz: '1.70' },
  { Processor: 'Intel Core i3-4150 ', Score: '1928', GHz: '3.50' },
  { Processor: 'Intel Pentium G3460 ', Score: '1927', GHz: '3.50' },
  { Processor: 'Intel Core i3-8100T ', Score: '1925', GHz: '3.10' },
  { Processor: 'Intel Core i5-4690T ', Score: '1919', GHz: '2.50' },
  { Processor: 'Intel Core i5-6500TE ', Score: '1916', GHz: '2.30' },
  { Processor: 'Intel Celeron G4900 ', Score: '1910', GHz: '3.10' },
  { Processor: 'Intel Core i3-4370T ', Score: '1908', GHz: '3.30' },
  { Processor: 'Intel Core i3-6100T ', Score: '1900', GHz: '3.20' },
  { Processor: 'Intel Core i5-3470S ', Score: '1897', GHz: '2.90' },
  { Processor: 'Intel Core i5-4460 ', Score: '1896', GHz: '3.20' },
  { Processor: 'Intel Pentium G3440 ', Score: '1895', GHz: '3.30' },
  { Processor: 'Intel Pentium G3430 ', Score: '1891', GHz: '3.30' },
  { Processor: 'Intel Core i3-4130 ', Score: '1888', GHz: '3.40' },
  { Processor: 'Intel Core i5-3450 ', Score: '1881', GHz: '3.10' },
  { Processor: 'Intel Core i5-4570R ', Score: '1875', GHz: '2.70' },
  { Processor: 'Intel Pentium G4600T ', Score: '1870', GHz: '3.00' },
  { Processor: 'Intel Pentium G3260 ', Score: '1864', GHz: '3.30' },
  { Processor: 'Intel Core i5-4440 ', Score: '1858', GHz: '3.10' },
  { Processor: 'Intel Core i3-4360T ', Score: '1852', GHz: '3.20' },
  { Processor: 'Intel Core i5-3450S ', Score: '1849', GHz: '2.80' },
  { Processor: 'Intel Pentium G4500T ', Score: '1845', GHz: '3.00' },
  { Processor: 'Intel Core i7-4700EQ ', Score: '1842', GHz: '2.40' },
  { Processor: 'Intel Pentium G2140 ', Score: '1837', GHz: '3.30' },
  { Processor: 'Intel Core i5-6500T ', Score: '1836', GHz: '2.50' },
  { Processor: 'Intel Pentium G3258 ', Score: '1830', GHz: '3.20' },
  { Processor: 'Intel Core i5-4430 ', Score: '1827', GHz: '3.00' },
  { Processor: 'Intel Core i5-4460S ', Score: '1823', GHz: '2.90' },
  { Processor: 'Intel Core i5-7400T ', Score: '1822', GHz: '2.40' },
  { Processor: 'Intel Core i7-3970X ', Score: '1820', GHz: '3.50' },
  { Processor: 'Intel Core i7-3960X ', Score: '1816', GHz: '3.30' },
  { Processor: 'Intel Celeron G3950 ', Score: '1811', GHz: '3.00' },
  { Processor: 'Intel Pentium G4400T ', Score: '1810', GHz: '2.90' },
  { Processor: 'Intel Pentium G3250 ', Score: '1809', GHz: '3.20' },
  { Processor: 'Intel Core i3-3240 ', Score: '1808', GHz: '3.40' },
  { Processor: 'Intel Core i3-4170T ', Score: '1806', GHz: '3.20' },
  { Processor: 'Intel Core i5-4400E ', Score: '1804', GHz: '2.70' },
  { Processor: 'Intel Core i3-3245 ', Score: '1803', GHz: '3.40' },
  { Processor: 'Intel Celeron G4900T ', Score: '1799', GHz: '2.90' },
  { Processor: 'Intel Core i7-2700K ', Score: '1798', GHz: '3.50' },
  { Processor: 'Intel Celeron G3930 ', Score: '1796', GHz: '2.90' },
  { Processor: 'Intel Pentium G3420 ', Score: '1795', GHz: '3.20' },
  { Processor: 'Intel Core i5-4200H ', Score: '1790', GHz: '2.80' },
  { Processor: 'Intel Core i3-3250 ', Score: '1785', GHz: '3.50' },
  { Processor: 'Intel Core i5-3475S ', Score: '1783', GHz: '2.90' },
  { Processor: 'Intel Core i5-3350P ', Score: '1783', GHz: '3.10' },
  { Processor: 'Intel Core i5-3340 ', Score: '1782', GHz: '3.10' },
  { Processor: 'Intel Core i5-3570T ', Score: '1779', GHz: '2.30' },
  { Processor: 'Intel Pentium G4560T ', Score: '1777', GHz: '2.90' },
  { Processor: 'Intel Core i5-4440S ', Score: '1767', GHz: '2.80' },
  { Processor: 'Intel Core i5-3470T ', Score: '1765', GHz: '2.90' },
  { Processor: 'Intel Celeron G4930T ', Score: '1761', GHz: '3.00' },
  { Processor: 'Intel Core i5-2550K ', Score: '1761', GHz: '3.40' },
  { Processor: 'Intel Core i5-4470S ', Score: '1756', GHz: '3.00' },
  { Processor: 'Intel Core i7-3820 ', Score: '1752', GHz: '3.60' },
  { Processor: 'Intel Pentium G2130 ', Score: '1749', GHz: '3.20' },
  { Processor: 'Intel Core i5-3340S ', Score: '1747', GHz: '2.80' },
  { Processor: 'Intel Pentium G3240 ', Score: '1742', GHz: '3.10' },
  { Processor: 'Intel Core i3-4160T ', Score: '1742', GHz: '3.10' },
  { Processor: 'Intel Core i7-3930K ', Score: '1739', GHz: '3.20' },
  { Processor: 'Intel Core i5-3335S ', Score: '1738', GHz: '2.70' },
  { Processor: 'Intel Core i7-2600 ', Score: '1734', GHz: '3.40' },
  { Processor: 'Intel Core i7-2600K ', Score: '1733', GHz: '3.40' },
  { Processor: 'Intel Pentium G3450 ', Score: '1730', GHz: '3.40' },
  { Processor: 'Intel Core i7-4785T ', Score: '1727', GHz: '2.20' },
  { Processor: 'Intel Core i3-3220 ', Score: '1725', GHz: '3.30' },
  { Processor: 'Intel Core i5-4670T ', Score: '1722', GHz: '2.30' },
  { Processor: 'Intel Celeron G3900 ', Score: '1721', GHz: '2.80' },
  { Processor: 'Intel Core i3-3225 ', Score: '1719', GHz: '3.30' },
  { Processor: 'Intel Core i3-4330T ', Score: '1717', GHz: '3.00' },
  { Processor: 'Intel Core i5-3330 ', Score: '1717', GHz: '3.00' },
  { Processor: 'Intel Pentium G2120 ', Score: '1716', GHz: '3.10' },
  { Processor: 'Intel Celeron G3930TE ', Score: '1706', GHz: '2.70' },
  { Processor: 'Intel Core i5-2500 ', Score: '1703', GHz: '3.30' },
  { Processor: 'Intel Core i5-2500K ', Score: '1699', GHz: '3.30' },
  { Processor: 'Intel Pentium G3220 ', Score: '1697', GHz: '3.00' },
  { Processor: 'Intel Celeron G3920 ', Score: '1695', GHz: '2.90' },
  { Processor: 'Intel Core i3-4570T ', Score: '1693', GHz: '2.90' },
  { Processor: 'Intel Celeron G1850 ', Score: '1692', GHz: '2.90' },
  { Processor: 'Intel Core i3-4350 ', Score: '1692', GHz: '3.60' },
  { Processor: 'Intel Core i3-3250T ', Score: '1684', GHz: '3.00' },
  { Processor: 'Intel Core i7-995X ', Score: '1683', GHz: '3.60' },
  { Processor: 'Intel Core i3-3210 ', Score: '1683', GHz: '3.20' },
  { Processor: 'Intel Core i7-2600S ', Score: '1680', GHz: '2.80' },
  { Processor: 'Intel Core i5-4570TE ', Score: '1672', GHz: '2.70' },
  { Processor: 'Intel Core i5-4430S ', Score: '1670', GHz: '2.70' },
  { Processor: 'Intel Core i5-3330S ', Score: '1667', GHz: '2.70' },
  { Processor: 'Intel Core i5-6400T ', Score: '1664', GHz: '2.20' },
  { Processor: 'Intel Core i3-6100TE ', Score: '1658', GHz: '2.70' },
  { Processor: 'Intel Core i5-2450P ', Score: '1654', GHz: '3.20' },
  { Processor: 'Intel Core i5-2500S ', Score: '1651', GHz: '2.70' },
  { Processor: 'Intel Core i5-4590T ', Score: '1650', GHz: '2.00' },
  { Processor: 'Intel Celeron G3930T ', Score: '1650', GHz: '2.70' },
  { Processor: 'Intel Core i3-4130T ', Score: '1636', GHz: '2.90' },
  { Processor: 'Intel Celeron G3900T ', Score: '1629', GHz: '2.60' },
  { Processor: 'Intel Pentium G3440T ', Score: '1615', GHz: '2.80' },
  { Processor: 'Intel Celeron G1840 ', Score: '1614', GHz: '2.80' },
  { Processor: 'Intel Pentium G2030 ', Score: '1611', GHz: '3.00' },
  { Processor: 'Intel Core i5-2380P ', Score: '1607', GHz: '3.10' },
  { Processor: 'Intel Core i7-7Y75 ', Score: '1605', GHz: '1.30' },
  { Processor: 'Intel Core i7-990X ', Score: '1601', GHz: '3.47' },
  { Processor: 'Intel Core i7-4610Y ', Score: '1583', GHz: '1.70' },
  { Processor: 'Intel Core i3-4150T ', Score: '1582', GHz: '3.00' },
  { Processor: 'Intel Core i3-2130 ', Score: '1582', GHz: '3.40' },
  { Processor: 'Intel Core i7-4765T ', Score: '1581', GHz: '2.00' },
  { Processor: 'Intel Core i5-2400 ', Score: '1578', GHz: '3.10' },
  { Processor: 'Intel Core i5-680 ', Score: '1577', GHz: '3.60' },
  { Processor: 'Intel Pentium G3420T ', Score: '1570', GHz: '2.70' },
  { Processor: 'Intel Pentium G3260T ', Score: '1568', GHz: '2.90' },
  { Processor: 'Intel Pentium G2020 ', Score: '1568', GHz: '2.90' },
  { Processor: 'Intel Pentium G3240T ', Score: '1558', GHz: '2.70' },
  { Processor: 'Intel Core i7-980 ', Score: '1558', GHz: '3.33' },
  { Processor: 'Intel Pentium G3250T ', Score: '1553', GHz: '2.80' },
  { Processor: 'Intel Core i3-2125 ', Score: '1550', GHz: '3.30' },
  { Processor: 'Intel Core i5-670 ', Score: '1549', GHz: '3.47' },
  { Processor: 'Intel Celeron G1630 ', Score: '1542', GHz: '2.80' },
  { Processor: 'Intel Core i3-3220T ', Score: '1531', GHz: '2.80' },
  { Processor: 'Intel Core i7-980X ', Score: '1525', GHz: '3.33' },
  { Processor: 'Intel Core i3-3240T ', Score: '1525', GHz: '2.90' },
  { Processor: 'Intel Celeron G1820 ', Score: '1522', GHz: '2.70' },
  { Processor: 'Intel Core i5-2320 ', Score: '1518', GHz: '3.00' },
  { Processor: 'Intel Core i3-2120 ', Score: '1517', GHz: '3.30' },
  { Processor: 'Intel Celeron G1830 ', Score: '1513', GHz: '2.80' },
  { Processor: 'Intel Core i7-970 ', Score: '1498', GHz: '3.20' },
  { Processor: 'Intel Core i7-975 ', Score: '1495', GHz: '3.33' },
  { Processor: 'Intel Core i5-2310 ', Score: '1494', GHz: '2.90' },
  { Processor: 'Intel Pentium G2120T ', Score: '1493', GHz: '2.70' },
  { Processor: 'Intel Core i5-2405S ', Score: '1485', GHz: '2.50' },
  { Processor: 'Intel Core i5-2400S ', Score: '1468', GHz: '2.50' },
  { Processor: 'Intel Core i5-4460T ', Score: '1463', GHz: '1.90' },
  { Processor: 'Intel Pentium G2010 ', Score: '1461', GHz: '2.80' },
  { Processor: 'Intel Pentium G2100T ', Score: '1460', GHz: '2.60' },
  { Processor: 'Intel Pentium G870 ', Score: '1460', GHz: '3.10' },
  { Processor: 'Intel Core i5-660 ', Score: '1460', GHz: '3.33' },
  { Processor: 'Intel Core i7-4770TE ', Score: '1458', GHz: '2.30' },
  { Processor: 'Intel Core i7-960 ', Score: '1457', GHz: '3.20' },
  { Processor: 'Intel Core i7-965 ', Score: '1452', GHz: '3.20' },
  { Processor: 'Intel Core2 Extreme X9775 ', Score: '1451', GHz: '3.20' },
  { Processor: 'Intel Core i5-2500T ', Score: '1447', GHz: '2.30' },
  { Processor: 'Intel Pentium G2030T ', Score: '1446', GHz: '2.60' },
  { Processor: 'Intel Core i5-655K ', Score: '1446', GHz: '3.20' },
  { Processor: 'Intel Pentium G3220T ', Score: '1436', GHz: '2.60' },
  { Processor: 'Intel Core i5-661 ', Score: '1436', GHz: '3.33' },
  { Processor: 'Intel Celeron G1840T ', Score: '1431', GHz: '2.50' },
  { Processor: 'Intel Core i5-2300 ', Score: '1431', GHz: '2.80' },
  { Processor: 'Intel Core i3-2100 ', Score: '1427', GHz: '3.10' },
  { Processor: 'Intel Pentium Silver J5040 ', Score: '1422', GHz: '2.00' },
  { Processor: 'Intel Core i7-860S ', Score: '1413', GHz: '2.53' },
  { Processor: 'Intel Core i7-870 ', Score: '1405', GHz: '2.93' },
  { Processor: 'Intel Core i3-2102 ', Score: '1400', GHz: '3.10' },
  { Processor: 'Intel Core i3-4330TE ', Score: '1399', GHz: '2.40' },
  { Processor: 'Intel Core2 Extreme X9770 ', Score: '1398', GHz: '3.20' },
  { Processor: 'Intel Core i5-24050S ', Score: '1397', GHz: '2.50' },
  { Processor: 'Intel Celeron G1820T ', Score: '1396', GHz: '2.40' },
  { Processor: 'Intel Core2 Duo E8600 ', Score: '1396', GHz: '3.33' },
  { Processor: 'Intel Core i5-2390T ', Score: '1394', GHz: '2.70' },
  { Processor: 'Intel Core i7-950 ', Score: '1394', GHz: '3.07' },
  { Processor: 'Intel Core i5-650 ', Score: '1382', GHz: '3.20' },
  { Processor: 'Intel Celeron G1620 ', Score: '1377', GHz: '2.70' },
  { Processor: 'Intel Pentium E6800 ', Score: '1376', GHz: '3.33' },
  { Processor: 'Intel Core i7-875K ', Score: '1367', GHz: '2.93' },
  { Processor: 'Intel Pentium G860 ', Score: '1360', GHz: '3.00' },
  { Processor: 'Intel Core i7-880 ', Score: '1356', GHz: '3.07' },
  { Processor: 'Intel Core i3-550 ', Score: '1353', GHz: '3.20' },
  { Processor: 'Intel Core i7-940 ', Score: '1349', GHz: '2.93' },
  { Processor: 'Intel Core i3-560 ', Score: '1341', GHz: '3.33' },
  { Processor: 'Intel Pentium G2020T ', Score: '1338', GHz: '2.50' },
  { Processor: 'Intel Core i7-860 ', Score: '1321', GHz: '2.80' },
  { Processor: 'Intel Celeron G1620T ', Score: '1321', GHz: '2.40' },
  { Processor: 'Intel Pentium E5800 ', Score: '1320', GHz: '3.20' },
  { Processor: 'Intel Core2 Duo E8500 ', Score: '1315', GHz: '3.16' },
  { Processor: 'Intel Pentium G3320TE ', Score: '1314', GHz: '2.30' },
  { Processor: 'Intel Celeron G1610 ', Score: '1311', GHz: '2.60' },
  { Processor: 'Intel Core i5-760 ', Score: '1307', GHz: '2.80' },
  { Processor: 'Intel Pentium G640 ', Score: '1306', GHz: '2.80' },
  { Processor: 'Intel Pentium G850 ', Score: '1305', GHz: '2.90' },
  { Processor: 'Intel Pentium E6700 ', Score: '1292', GHz: '3.20' },
  { Processor: 'Intel Core2 Duo E7600 ', Score: '1291', GHz: '3.06' },
  { Processor: 'Intel Core2 Quad Q9650 ', Score: '1288', GHz: '3.00' },
  { Processor: 'Intel Core2 Extreme X9650 ', Score: '1282', GHz: '3.00' },
  { Processor: 'Intel Core i7-930 ', Score: '1279', GHz: '2.80' },
  { Processor: 'Intel Pentium G645 ', Score: '1278', GHz: '2.90' },
  { Processor: 'Intel Core i3-540 ', Score: '1275', GHz: '3.07' },
  { Processor: 'Intel Celeron G555 ', Score: '1275', GHz: '2.70' },
  { Processor: 'Intel Core i3-2105 ', Score: '1274', GHz: '3.10' },
  { Processor: 'Intel Celeron J4025 ', Score: '1273', GHz: '2.00' },
  { Processor: 'Intel Core i7-870S ', Score: '1270', GHz: '2.67' },
  { Processor: 'Intel Pentium G6960 ', Score: '1264', GHz: '2.93' },
  { Processor: 'Intel Pentium G630 ', Score: '1245', GHz: '2.70' },
  { Processor: 'Intel Core2 Duo E8400 ', Score: '1243', GHz: '3.00' },
  { Processor: 'Intel Core i5-750 ', Score: '1243', GHz: '2.67' },
  { Processor: 'Intel Pentium G840 ', Score: '1240', GHz: '2.80' },
  { Processor: 'Intel Core i7-2655LE ', Score: '1231', GHz: '2.20' },
  { Processor: 'Intel Core2 Quad Q9550 ', Score: '1227', GHz: '2.83' },
  { Processor: 'Intel Core2 Extreme Q6850 ', Score: '1227', GHz: '3.00' },
  { Processor: 'Intel Pentium E6500 ', Score: '1224', GHz: '2.93' },
  { Processor: 'Intel Core i7-920 ', Score: '1223', GHz: '2.67' },
  { Processor: 'Intel Core2 Quad Q9500 ', Score: '1222', GHz: '2.83' },
  { Processor: 'Intel Pentium G620 ', Score: '1217', GHz: '2.60' },
  { Processor: 'Intel Pentium G6950 ', Score: '1217', GHz: '2.80' },
  { Processor: 'Intel Core2 Duo E7500 ', Score: '1211', GHz: '2.93' },
  { Processor: 'Intel Core2 Extreme Q6800 ', Score: '1202', GHz: '2.93' },
  { Processor: 'Intel Core2 Duo E6850 ', Score: '1198', GHz: '3.00' },
  { Processor: 'Intel Core i5-3439Y ', Score: '1197', GHz: '1.50' },
  { Processor: 'Intel Pentium G645T ', Score: '1195', GHz: '2.50' },
  { Processor: 'Intel Pentium Silver J5005 ', Score: '1191', GHz: '1.50' },
  { Processor: 'Intel Core i5-2515E ', Score: '1189', GHz: '2.50' },
  { Processor: 'Intel Celeron G550 ', Score: '1189', GHz: '2.60' },
  { Processor: 'Intel Core i5-2415M ', Score: '1189', GHz: '2.30' },
  { Processor: 'Intel Core2 Quad Q9505 ', Score: '1187', GHz: '2.83' },
  { Processor: 'Intel Core i3-530 ', Score: '1179', GHz: '2.93' },
  { Processor: 'Intel Pentium E5700 ', Score: '1170', GHz: '3.00' },
  { Processor: 'Intel Core2 Duo E8300 ', Score: '1169', GHz: '2.83' },
  { Processor: 'Intel Celeron J4125 ', Score: '1168', GHz: '2.00' },
  { Processor: 'Intel Celeron G1610T ', Score: '1166', GHz: '2.30' },
  { Processor: 'Intel Celeron E3500 ', Score: '1154', GHz: '2.70' },
  { Processor: 'Intel Core2 Quad Q8400 ', Score: '1153', GHz: '2.66' },
  { Processor: 'Intel Pentium E6300 ', Score: '1144', GHz: '2.80' },
  { Processor: 'Intel Core2 Quad Q9450 ', Score: '1141', GHz: '2.66' },
  { Processor: 'Intel Core2 Quad Q9400 ', Score: '1139', GHz: '2.66' },
  { Processor: 'Intel Pentium G6951 ', Score: '1137', GHz: '2.80' },
  { Processor: 'Intel Celeron G540 ', Score: '1135', GHz: '2.50' },
  { Processor: 'Intel Pentium G3450T ', Score: '1135', GHz: '2.90' },
  { Processor: 'Intel Pentium E5500 ', Score: '1135', GHz: '2.80' },
  { Processor: 'Intel Celeron J4005 ', Score: '1127', GHz: '2.00' },
  { Processor: 'Intel Core i3-2100T ', Score: '1119', GHz: '2.50' },
  { Processor: 'Intel Core i3-2120T ', Score: '1108', GHz: '2.60' },
  { Processor: 'Intel Core2 Duo E8200 ', Score: '1108', GHz: '2.66' },
  { Processor: 'Intel Pentium E6600 ', Score: '1108', GHz: '3.06' },
  { Processor: 'Intel Core2 Duo E7400 ', Score: '1105', GHz: '2.80' },
  { Processor: 'Intel Celeron J4105 ', Score: '1102', GHz: '1.50' },
  { Processor: 'Intel Pentium G640T ', Score: '1102', GHz: '2.40' },
  { Processor: 'Intel Core2 Extreme X6800 ', Score: '1094', GHz: '2.93' },
  { Processor: 'Intel Core2 Duo E7300 ', Score: '1085', GHz: '2.66' },
  { Processor: 'Intel Core i5-4302Y ', Score: '1084', GHz: '1.60' },
  { Processor: 'Intel Core2 Quad Q9300 ', Score: '1084', GHz: '2.50' },
  { Processor: 'Intel Core2 Extreme Q9200 ', Score: '1080', GHz: '2.40' },
  { Processor: 'Intel Core2 Duo E7200 ', Score: '1080', GHz: '2.53' },
  { Processor: 'Intel Celeron G530 ', Score: '1079', GHz: '2.40' },
  { Processor: 'Intel Core i5-4202Y ', Score: '1079', GHz: '1.60' },
  { Processor: 'Intel Core2 Quad Q6700 ', Score: '1076', GHz: '2.66' },
  { Processor: 'Intel Celeron J4115 ', Score: '1073', GHz: '1.80' },
  { Processor: 'Intel Pentium E5400 ', Score: '1072', GHz: '2.70' },
  { Processor: 'Intel Core i3-2330E ', Score: '1063', GHz: '2.20' },
  { Processor: 'Intel Core2 Quad Q8300 ', Score: '1055', GHz: '2.50' },
  { Processor: 'Intel Core2 Duo E6750 ', Score: '1051', GHz: '2.66' },
  { Processor: 'Intel Core2 Duo E6700 ', Score: '1047', GHz: '2.66' },
  { Processor: 'Intel Core2 Extreme Q9300 ', Score: '1046', GHz: '2.53' },
  { Processor: 'Intel Core i5 750S ', Score: '1039', GHz: '2.40' },
  { Processor: 'Intel Celeron E3300 ', Score: '1032', GHz: '2.50' },
  { Processor: 'Intel Core i5 E 520 ', Score: '1032', GHz: '2.40' },
  { Processor: 'Intel Pentium E5300 ', Score: '1028', GHz: '2.60' },
  { Processor: 'Intel Celeron G550T ', Score: '1026', GHz: '2.20' },
  { Processor: 'Intel Core2 Quad Q8200 ', Score: '1014', GHz: '2.33' },
  { Processor: 'Intel Pentium G630T ', Score: '1009', GHz: '2.30' },
  { Processor: 'Intel Core i5-4210Y ', Score: '1004', GHz: '1.50' },
  { Processor: 'Intel Pentium E5200 ', Score: '1002', GHz: '2.50' },
]

export const GPU_DATA = [
  { graphicsCard: 'RTX 3090', Score: '654' },
  { graphicsCard: 'RTX A6000', Score: '628' },
  { graphicsCard: 'RTX A5000', Score: '564' },
  { graphicsCard: 'RTX 3080', Score: '549' },
  { graphicsCard: 'A100-SXM4-40GB', Score: '505' },
  { graphicsCard: 'A100-PCIE-40GB', Score: '498' },
  { graphicsCard: 'RTX 3070', Score: '399' },
  { graphicsCard: 'RTX 3080 Laptop GPU', Score: '376' },
  { graphicsCard: 'Quadro RTX 6000', Score: '375' },
  { graphicsCard: 'RTX 3060 Ti', Score: '372' },
  { graphicsCard: 'Quadro RTX 8000', Score: '362' },
  { graphicsCard: 'RTX 3070 Laptop GPU', Score: '362' },
  { graphicsCard: 'TITAN RTX', Score: '361' },
  { graphicsCard: 'Tesla V100-SXM2-16GB', Score: '352' },
  { graphicsCard: 'RTX 2080 Ti', Score: '348' },
  { graphicsCard: 'TITAN V', Score: '332' },
  { graphicsCard: 'Tesla V100-PCIE-32GB', Score: '323' },
  { graphicsCard: 'GRID RTX6000-8Q', Score: '311' },
  { graphicsCard: 'RTX 3060 Laptop GPU', Score: '306' },
  { graphicsCard: 'GRID RTX8000P-8Q', Score: '302' },
  { graphicsCard: 'RTX 3060', Score: '289' },
  { graphicsCard: 'RTX 2080 SUPER', Score: '264' },
  { graphicsCard: 'RTX 2070 SUPER', Score: '255' },
  { graphicsCard: 'RTX 2070 Super', Score: '255' },
  { graphicsCard: 'RTX 2080', Score: '255' },
  { graphicsCard: 'Quadro RTX 5000', Score: '248' },
  { graphicsCard: 'Quadro GP100', Score: '240' },
  { graphicsCard: 'RTX 2060 SUPER', Score: '236' },
  { graphicsCard: 'Quadro RTX 4000', Score: '228' },
  { graphicsCard: 'RTX 2080 Super', Score: '223' },
  { graphicsCard: 'RTX 2070', Score: '223' },
  { graphicsCard: 'Tesla P100-PCIE-16GB', Score: '221' },
  { graphicsCard: 'TITAN Xp', Score: '215' },
  { graphicsCard: 'TITAN Xp COLLECTORS EDITION', Score: '212' },
  { graphicsCard: 'RTX 2080 Super with Max-Q Design', Score: '211' },
  { graphicsCard: 'RTX 2080 with Max-Q Design', Score: '203' },
  { graphicsCard: 'GTX 1080 Ti', Score: '191' },
  { graphicsCard: 'RTX 2070 with Max-Q Design', Score: '191' },
  { graphicsCard: 'TITAN X (Pascal)', Score: '189' },
  { graphicsCard: 'RTX 2070 Super with Max-Q Design', Score: '184' },
  { graphicsCard: 'P102-100', Score: '184' },
  { graphicsCard: 'Quadro P6000', Score: '183' },
  { graphicsCard: 'GRID T4-8Q', Score: '183' },
  { graphicsCard: 'RTX 2060', Score: '180' },
  { graphicsCard: 'Quadro RTX 3000', Score: '177' },
  { graphicsCard: 'Tesla T4', Score: '167' },
  { graphicsCard: 'RTX 2060 with Max-Q Design', Score: '153' },
  { graphicsCard: 'GTX 980 Ti', Score: '137' },
  { graphicsCard: 'GTX 1080', Score: '130' },
  { graphicsCard: 'GTX TITAN X', Score: '129' },
  { graphicsCard: 'GTX 1660 SUPER', Score: '129' },
  { graphicsCard: 'GTX 1660 Ti', Score: '128' },
  { graphicsCard: 'GTX 1070 Ti', Score: '127' },
  { graphicsCard: 'P104-100', Score: '124' },
  { graphicsCard: 'Quadro P5000', Score: '124' },
  { graphicsCard: 'GTX 1660 Ti with Max-Q Design', Score: '122' },
  { graphicsCard: 'Quadro M6000', Score: '118' },
  { graphicsCard: 'GTX 1070', Score: '118' },
  { graphicsCard: 'GTX 1660', Score: '112' },
  { graphicsCard: 'GTX 1070 with Max-Q Design', Score: '111' },
  { graphicsCard: 'GTX TITAN Black', Score: '105' },
  { graphicsCard: 'GTX 780 Ti', Score: '103' },
  { graphicsCard: 'Tesla M40', Score: '98' },
  { graphicsCard: 'Tesla P6', Score: '95' },
  { graphicsCard: 'GTX 1650 SUPER', Score: '93' },
  { graphicsCard: 'GTX 980', Score: '91' },
  { graphicsCard: 'Quadro RTX 5000 with Max-Q Design', Score: '91' },
  { graphicsCard: 'Quadro M5000', Score: '87' },
  { graphicsCard: 'Quadro K6000', Score: '87' },
  { graphicsCard: 'Quadro P3200', Score: '86' },
  { graphicsCard: 'GTX 1650 Ti', Score: '85' },
  { graphicsCard: 'GTX 780', Score: '82' },
  { graphicsCard: 'Tesla M60', Score: '82' },
  { graphicsCard: 'Quadro P4000', Score: '81' },
  { graphicsCard: 'GTX 1060 6GB', Score: '79' },
  { graphicsCard: 'GTX 970', Score: '79' },
  { graphicsCard: 'GTX 1060 3GB', Score: '76' },
  { graphicsCard: 'GTX 1060', Score: '76' },
  { graphicsCard: 'GTX 1650 Ti with Max-Q Design', Score: '73' },
  { graphicsCard: 'GTX 1650', Score: '72' },
  { graphicsCard: 'Quadro T1000', Score: '71' },
  { graphicsCard: 'Quadro M4000M', Score: '68' },
  { graphicsCard: 'GTX 1650 with Max-Q Design', Score: '66' },
  { graphicsCard: 'Tesla K20Xm', Score: '63' },
  { graphicsCard: 'Tesla K80', Score: '62' },
  { graphicsCard: 'GTX 980M', Score: '59' },
  { graphicsCard: 'Quadro M4000', Score: '58' },
  { graphicsCard: 'Quadro P2000', Score: '56' },
  { graphicsCard: 'GTX 970M', Score: '56' },
  { graphicsCard: 'GTX 1060 with Max-Q Design', Score: '55' },
  { graphicsCard: 'Tesla K20c', Score: '55' },
  { graphicsCard: 'Quadro P2200', Score: '53' },
  { graphicsCard: 'GTX 1050 Ti with Max-Q Design', Score: '48' },
  { graphicsCard: 'GTX 1050 Ti', Score: '48' },
  { graphicsCard: 'GTX 950', Score: '44' },
  { graphicsCard: 'GTX 960', Score: '44' },
  { graphicsCard: 'GTX 770', Score: '43' },
  { graphicsCard: 'GTX 680', Score: '43' },
  { graphicsCard: 'Quadro M2200', Score: '42' },
  { graphicsCard: 'GTX 1050', Score: '40' },
  { graphicsCard: 'GTX 670', Score: '39' },
  { graphicsCard: 'Quadro RTX 3000 with Max-Q Design', Score: '39' },
  { graphicsCard: 'GTX 1050 with Max-Q Design', Score: '37' },
  { graphicsCard: 'GTX 760', Score: '36' },
  { graphicsCard: 'Quadro M1200', Score: '34' },
  { graphicsCard: 'Quadro M2000M', Score: '34' },
  { graphicsCard: 'Quadro K2200', Score: '33' },
  { graphicsCard: 'GTX 860M', Score: '33' },
  { graphicsCard: 'GTX 750 Ti', Score: '33' },
  { graphicsCard: 'Quadro K4200', Score: '32' },
  { graphicsCard: 'GTX 965M', Score: '32' },
  { graphicsCard: 'GTX 960M', Score: '31' },
  { graphicsCard: 'Quadro K5000', Score: '30' },
  { graphicsCard: 'GTX 750', Score: '30' },
  { graphicsCard: 'Quadro P1000', Score: '28' },
  { graphicsCard: 'MX350', Score: '28' },
  { graphicsCard: 'GTX 660', Score: '26' },
  { graphicsCard: 'GTX 950M', Score: '26' },
  { graphicsCard: 'Quadro M1000M', Score: '25' },
  { graphicsCard: 'Quadro P620', Score: '25' },
  { graphicsCard: 'GTX 850M', Score: '22' },
  { graphicsCard: 'MX250', Score: '22' },
  { graphicsCard: 'Quadro K4000', Score: '20' },
  { graphicsCard: 'GT 1030', Score: '20' },
  { graphicsCard: 'Quadro M2000', Score: '19' },
  { graphicsCard: 'MX130', Score: '19' },
  { graphicsCard: 'Quadro M520', Score: '19' },
  { graphicsCard: 'Quadro K620', Score: '18' },
  { graphicsCard: 'MX110', Score: '14' },
  { graphicsCard: 'GT 740', Score: '13' },
  { graphicsCard: 'GT 730', Score: '13' },
  { graphicsCard: 'MX230', Score: '13' },
  { graphicsCard: 'GTX 650 Ti', Score: '12' },
  { graphicsCard: '930MX', Score: '11' },
  { graphicsCard: '940M', Score: '11' },
  { graphicsCard: 'GTX 1080 with Max-Q Design', Score: '10' },
  { graphicsCard: '920MX', Score: '10' },
  { graphicsCard: 'GT 710', Score: '6' },
  { graphicsCard: 'NVS 510', Score: '5' },
  { graphicsCard: '940MX', Score: '4' },
]

export const RAYVISION_CPUS = 2
export const RAYVISION_GHZ = 2.2
export const RAYVISION_SCORES = 16
// 瑞云CPU的默认跑分 1510
export const RAYVISION_CPU_SCORE = 1510
// 瑞云GPU GTX1080 Ti的默认计算能力 191
export const RAYVISION_GPU_POWER = 191
// 瑞云GPU GTX1080的默认显卡数
export const RAYVISION_GPU_NUMBER = 2
// 瑞云CPU默认显卡数
export const RAYVISION_CPU_NUMBER = 2
// NOTE: [Diamond, Platinum, Gold, Sliver, Ordinary]
export const GPU_PRICE = [2, 2.4, 2.8, 3.2, 4]
export const CPU_PRICE = [0.125, 0.15, 0.175, 0.2, 0.25]
// 用户选择显卡张数列表
export const RAYVISION_GPU_NUMBER_LIST = [1, 2]
