import styled, { css } from 'styled-components'
import { color, typography, transition, spacing } from '@renderbus/common/theme'
import SelectPng from '../../../images/pricing/select.png'

export const MemberSelectOptionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 120px;
  background: rgba(51, 51, 51, 0.9);
  border: 1px solid rgba(153, 153, 153, 0.6);
  border-radius: 6px;
  display: ${p => (p.showMemberOption ? 'flex' : 'none')};
  flex-direction: column;
  align-item: flex-start;
  span {
    text-align: start;
    font-size: 14px;
    padding: 8px 11px;
    &:hover {
      background: #222222;
      border-radius: 6px;
    }
  }
`

export const MemberSelectWrapper = styled.div`
  position: relative;
  margin: 25px auto 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  > span {
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 11px;
    width: 10px;
    height: 10px;
    border-right: 2px solid #dddddd;
    border-bottom: 2px solid #dddddd;
    transform: rotate(45deg);
  }
`

export const MerchineInput = styled.input`
  border: none;
  outline: none;
  width: 90px;
  color: #dddddd;
  appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const MerchineInputWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  border: 1px solid #999999;
  border-radius: 6px;
  width: 140px;
  padding: 0 9px 0 0;
  box-sizing: content-box;
  color: #999999;
  background: rgba(51, 51, 51, 0.4);
  input {
    opacity: 0.4;
  }
  input:focus {
    opacity: 1;
  }
`

export const RenderResultCard = styled.div`
  width: 300px;
  height: 160px;
  background: linear-gradient(45deg, rgba(19, 203, 117, 0.4) 0%, rgba(51, 51, 51, 0.4) 100%);
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
  padding: 20px;
  & + & {
    margin-left: 20px;
  }
  .title {
    line-height: 16px;
    font-size: 16px;
  }
  .value {
    margin: 30px 0 16px 0;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    height: 24px;
    .big {
      font-size: 30px;
    }
    .small {
      font-size: 16px;
    }
  }
`

export const RenderDuration = styled.div`
  width: 620px;
  height: 50px;
  background: linear-gradient(90deg, rgba(19, 203, 117, 0.4) 0%, rgba(51, 51, 51, 0.4) 100%);
  border-radius: 10px;
  display: flex;
  padding: 0 30px 0 21px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 21px;
  margin-top: 12px;
  span:last-child {
    font-size: 24px;
  }
  .duration {
    .big {
      font-size: 30px;
    }
    .small {
      font-size: 16px;
    }
  }
`
const FormControl = css`
  color: ${color.prompt};
  width: 200px;
  height: 30px;
  line-height: 30px;
  padding: 0 0.5em;
  margin: ${spacing.small} 0;
  font-size: ${typography.textSmall};
  background-color: white;
  background-image: none;
  border: 1px solid ${color.divider};
  background-color: transparent;
  transition: ${transition(['border-color'])};
  :focus {
    outline: 0;
    border-color: ${color.primary};
  }
  :disabled {
    cursor: not-allowed;
  }
`
export const Input = styled.input`
  ${FormControl};
  border-radius: 6px;
  border: 1px solid #666666;
`
export const Select = styled.select`
  ${FormControl};
  position: relative;
  border-radius: 6px;
  border: 1px solid #666666;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: auto 20%;
  padding-right: 20px;
  &:not([multiple]):not([size]) {
    background-image: url(${SelectPng});
    background-position: calc(100% - 12px) center;
  }
`
export const Label = styled.label`
  display: inline-block;
  text-align: right;
  padding-right: 1em;
  font-size: ${typography.textSmall};
`
export const ResultWrap = styled.div`
  font-size: ${typography.textSmall};
`
export const Advanced = styled.p`
  font-size: ${typography.textSmall};
  text-align: right;
  padding-right: 45px;
`
export const AdvancedWrapper = styled.div`
  ${({ openAdvanced }) =>
    !openAdvanced &&
    css`
      display: none;
    `}
`
export const Icon = styled.span`
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 2px;
  height: 8px;
  width: 8px;
  border: 1px solid;
  transform: rotate(-45deg);
  border-style: solid;
  border-color: ${color.divider};
  border-width: 0 0 1px 1px;
`
export const Header = styled.h5`
  color: white;
  font-size: ${typography.text};
  margin: ${spacing.small} 0;
  font-weight: bold;
`
export const Title = styled.h3`
  color: white;
  text-align: left;
  font-size: 20px;
  color: #13cb75;
  font-weight: bold;
  line-height: 30px;
`
export const CpuCardWrapper = styled.div`
  color: ${color.prompt};
  padding: 30px;
  border-image: linear-gradient(45deg, #13cb75, #333333, #666666) 10 10;
  background: linear-gradient(30deg, rgba(0, 206, 118, 0.8) 0%, #222222 39%, #333333 91%);
  border-radius: 12px;
`
export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  background-color: ${color.primary};
  color: white;
  font-size: ${typography.h4};
`
export const ResultTitle = styled.div`
  flex: 1;
  padding-left: 90px;
`
export const ResultDes = styled.div`
  padding-right: 90px;
  font-weight: 700;
`
