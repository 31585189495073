import {
  hideSMDown,
  Media,
  spacing,
  transition,
  typography,
  color,
  mbSize,
  hideSMUp,
} from '@renderbus/common/theme'
import styled, { css } from 'styled-components'

export const Trigger = styled.div`
  color: ${p => (p.isActive ? '#FFF' : '#999999')};
  line-height: 16px;
  ${Media.lessThan(Media.small)} {
    color: #fff;
    font-size: ${mbSize(28)};
  }
`
export const RechargeButton = styled.a`
  width: 160px;
  height: 40px;
  background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
  box-shadow: 0px 4px 7px 1px rgba(64, 194, 93, 0.3);
  border-radius: 20px;
  line-height: 40px;
  margin: ${p => (p.isCPU ? '0 0 30px 0' : '0 0 40px 0')};
  display: none;
  ${p =>
    p.show &&
    css`
      display: inline-block;
    `}
  ${Media.lessThan(Media.small)} {
    display: inline-block;
    margin-bottom: ${p => (p.isCPU ? `${mbSize(49)}` : `${mbSize(59)}`)};
    width: ${mbSize(260)};
    height: ${mbSize(64)};
    line-height: ${mbSize(64)};
  }
`
export const BgImg = styled.img`
  position: absolute;
  width: ${p => (p.isActive ? '300px' : '260px')};
  left: ${p => (p.isActive ? '-20px' : '0')};
  top: ${p => (p.isActive ? (p.isCPU ? '-60px' : '-45px') : '0')};
  &.mb {
    display: block;
    ${hideSMUp};
    height: 100%;
    width: ${mbSize(640)};
  }
  &.noHover {
    display: ${p => (p.isActive ? 'none' : 'block')};
  }
  &.hover {
    display: ${p => (p.isActive ? 'block' : 'none')};
  }
  ${hideSMDown};
  ${Media.lessThan(Media.small)} {
    display: none;
    &.noHover {
      display: none;
    }
    &.hover {
      display: none;
    }
  }
`
export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-color: ${p => p.borderColor};
  background-color: ${p => p.backgroundColor || '#222'};
  transition: ${transition('background-color')};
  color: white;
  width: 1400px;
  font-weight: 500;
  :hover {
    background-color: ${p => p.backgroundColor || '#282828'};
  }
  :not(:first-of-type) {
    border-top: none;
  }
  p {
    margin: 0;
    line-height: 1;
  }
  div {
    border-color: ${p => p.borderColor};
  }
  ${hideSMDown};
`
export const GpuWrapper = styled.div`
  margin: 0 0 48px 53px;
  /* align-item: center; */
  justify-content: flex-start;
`
export const CoreWrapper = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const CoreSubWrapper = styled.div`
  display: flex;
  /* align-item: center; */
  flex-direction: column;
  justify-content: flex-start;
`

export const CoreContent = styled.div`
  margin-bottom: ${p => (p.isActive ? '18px' : '12px')};
  line-height: ${p => (p.isActive ? '20px' : '16px')};
  font-size: ${p => (p.isActive ? '18px' : '16px')};
  width: max-content;
  text-align: end;
  &.right {
    margin-bottom: ${p => (p.isActive ? '18px' : '12px')};
    color: ${p => (p.isActive ? p.color : '#13cb75')};
    font-size: ${p => (p.isActive ? '24px' : '18px')};
    font-weight: bold;
    height: ${p => (p.isActive ? '19px' : '16px')};
    span:last-child {
      color: ${p => (p.isActive ? p.color : '#FFF')};
      font-weight: 400;
      font-size: ${p => (p.isActive ? '18px' : '16px')};
    }
    &:last-child {
      margin: unset;
    }
  }
  &:last-child {
    margin: unset;
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${mbSize(30)};
    font-size: ${mbSize(30)};
    line-height: ${mbSize(36)};
    &.right {
      margin-bottom: ${mbSize(24)};
      font-size: ${mbSize(40)};
      color: #13cb75;
      height: ${mbSize(40)};
      span:last-child {
        color: #fff;
        font-size: ${mbSize(24)};
      }
    }
  }
`

export const CellContainer = styled.div`
  width: ${p => p.width || '100%'};
  flex-shrink: ${p => (p.width ? 0 : 1)};
  margin: ${p => (p.backgroundColor ? '' : `${spacing.base} 0`)};
  height: ${p => (p.backgroundColor ? '320px' : '380px')};
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${p => p.backgroundColor || ''};
  :not(:first-of-type, :last-of-type) {
    border-right-width: 1px;
    border-right-style: solid;
  }
`

export const CellTitle = styled.h5`
  margin: ${p => (p.isActive ? '25px 0 5px 0' : '19px 0 0 0')};
  display: inline-block;
  font-size: ${typography.h3};
  color: ${p => p.isActive && p.color};
  vertical-align: top;
  font-size: 20px;
  line-height: 20px;
  ${Media.lessThan(Media.small)} {
    margin: ${p => (p.isCPU ? mbSize(61) : mbSize(81))} 0 0 0;
    color: #fff;
    font-size: ${mbSize(48)};
    font-weight: 500;
  }
`

export const CellTips = styled.p`
  font-size: ${typography.textSmall};
  color: #999;
  font-weight: 400;
`

export const ReoriginalchargeCell = styled.div`
  font-size: ${typography.textSmall};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-conent: space-between;
  text-decoration: line-through;
  color: #999;
`

export const RechargeCell = styled.div`
  display: flex;
  font-size: 18px;
  /* align-items: center; */
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    text-align: end;
  }
`

export const GrayQi = styled.span`
  color: #999;
  font-size: 12px;
`

export const PriceMobileHeader = styled.div`
  padding: 0 ${spacing.large};
  height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.backgroundColor};
  font-size: ${typography.h4};
  font-weight: 500;
  color: white;
  margin-top: 10px;
  img {
    width: 30px;
    margin-right: 10px;
  }
`

export const PriceMobileBody = styled.div`
  margin-bottom: 15px;
  padding: ${spacing.small} ${spacing.large} 16px ${spacing.base};
  background-color: ${p => p.backgroundColor || 'transparent'};
  border: ${p => `1px solid ${p.borderColor}`};
  border-top: none;
  line-height: 30px;
  font-size: ${typography.text};
  font-weight: 500;
  color: white;
`

export const MobileContainer = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`

export const MobilePrice = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #13cb75;
`
export const Highlight = styled.span`
  color: #fff;
  transition: ${transition('background-color')};
  font-size: 48px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(96)};
  }
`
export const Light = styled.span`
  color: #fff;
  font-size: 16px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(32)};
    font-weight: normal;
  }
`
export const Littlelight = styled.span`
  color: #999999;
  font-size: 16px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(32)};
    font-weight: normal;
  }
`
export const ContentContainer = styled.div`
  padding: 60px 0;
  text-align: center;
  background: ${p => (p.theme === 'black' ? color.background : color.panel)};
  @media (max-width: 600px) {
    padding: ${spacing.base} 0 40px;
  }
`
export const RenderPriceContainer = styled.div`
  margin: auto;
  flex-wrap: wrap;
  align-item: center;
  max-width: 1400px;
  margin-bottom: 10px;
  position: relative;
  height: 320px;
  ${Media.greaterThan(Media.small)} {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    height: auto;
    display: flex;
    margin-bottom: ${mbSize(30)};
  }
`
export const PriceCard = styled.div`
  position: relative;
  padding: ${spacing.large} 0 70px;
  width: 260px;
  height: max-content;
  color: white;
  margin-top: ${p => (p.isActive ? (p.isCPU ? '' : '-50px') : '0px')};
  text-align: center;
  transition: ${transition('background')};
  overflow: hidden;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(640)};
    min-height: ${mbSize(560)};
    margin-bottom: 20px;
    margin-top: 0;
    padding: 0;
  }
`
export const ContentSubTitle = styled.p`
  font-size: ${typography.text};
  font-weight: bold;
  width: 268px;
  line-height: 38px;
  height: 38px;
  text-align: center;
  margin: ${p => (p.isActive ? '0 0 50px 0' : '0 0 70px 0')};
  span {
    ${p =>
      p.isActive &&
      css`
        color: ${p.color};
      `}
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.large};
    margin: 0 0 ${mbSize(60)} 0;
    width: 100%;
    span {
      color: ${p => p.color};
    }
  }
`
export const Line = styled.div`
  width: 160px;
  height: 2px;
  transition: ${transition('opacity')};
  margin: ${p => (p.isCPU ? '19px auto 37px' : '21px auto 46px')};
  ${p => p.lineStyle};
  opacity: 0.4;
  border-radius: 1px;
  ${p =>
    p.isActive &&
    css`
      opacity: 1;
      margin: ${p => (p.isCPU ? '19px auto 34px' : '19px auto 41px')};
    `}
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(260)};
    opacity: 1;
    margin: ${mbSize(30)} auto ${mbSize(56)};
    ${p =>
      p.isCPU &&
      css`
        margin: ${mbSize(20)} auto ${mbSize(60)};
      `}
  }
`
export const NuclearPrice = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 37px;
  font-size: 16px;
  margin-bottom: ${p => (p.isActive ? '31px' : '49px')};
  ${Media.lessThan(Media.small)} {
    padding: 0 ${mbSize(120)};
    margin-bottom: ${p => (p.isCPU ? mbSize(40) : mbSize(60))};
  }
`
