import styled from 'styled-components'
import {
  Section,
  H1,
  SectionTitle,
  SubLightSection,
  PriceSubSection,
} from '@renderbus/common/components'
import {
  Media,
  hideSMDown,
  typography,
  spacing,
  color,
  hideMDDown,
  mbSize,
  hideSMUp,
} from '@renderbus/common/theme'

export const BlackBg = styled.div`
  position: absolute;
  background: #181818;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  ${Media.lessThan(Media.small)} {
    background: #202020;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${Media.lessThan(Media.small)} {
    justify-content: center;
  }
`
export const BannerContainer = styled.div`
  position: relative;
  padding-top: 119px;
  height: 600px;
  color: white;
  h1 {
    letter-spacing: 10px;
    font-size: 60px;
  }
  p {
    font-size: 20px;
    color: #13cb75;
    padding-right: 10px;
    margin-top: 15px;
  }
  ${Media.lessThan(Media.small)} {
    height: 300px;
    padding-top: ${mbSize(90)};
    h1 {
      font-size: ${mbSize(60)};
      letter-spacing: ${mbSize(5)};
    }
    p {
      font-size: ${typography.text};
      margin-top: ${mbSize(8)};
      padding-right: ${mbSize(5)};
    }
  }
`

export const DesktopOnlySection = styled(PriceSubSection)`
  ${hideSMDown};
`
export const YouthCloudContainer = styled.div`
  position: relative;
  height: 600px;
  color: white;
  text-align: center;
  padding: 100px 0;
  p {
    line-height: 2em;
  }
  button + button {
    margin-left: ${spacing.base};
  }
  a {
    &.pc {
      ${hideMDDown};
    }
    &.mb {
      ${hideSMUp};
      font-size: ${mbSize(24)};
      padding: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    height: 300px;
    padding: 30px 0;
    a {
      width: ${mbSize(240)};
      height: ${mbSize(60)};
      line-height: ${mbSize(60)};
    }
    p {
      font-size: ${typography.textSmall};
    }
    ${H1} {
      font-size: ${typography.h2};
    }
    h2 {
      font-size: ${mbSize(48)};
    }
  }
`
export const Tips = styled.p`
  text-align: center;
  margin-top: 40px;
  color: ${color.prompt};
  font-size: ${typography.textSmall};
`
export const PriceSection = styled(Section)`
  background: none;
  padding: 55px 0 94px 0;
  margin-top: -320px;
  position: relative;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(-220)};
  }
`
export const PriceTitle = styled(SectionTitle)`
  font-size: ${typography.title};
`
export const CPUPriceTitle = styled(SectionTitle)`
  font-size: ${typography.title};
  margin-top: 52px;
  margin-bottom: 60px;
  ${Media.lessThan(Media.small)} {
    margin: unset;
    font-size: ${mbSize(48)};
  }
`
export const PriceSubTitle = styled.h5`
  margin: 0 auto 35px;
  position: relative;
  top: -51px;
  font-size: ${typography.textSmall};
  text-align: center;
  color: #999;
  ${Media.lessThan(Media.small)} {
    top: 0;
    margin-bottom: ${mbSize(60)};
  }
`
export const PriceTips = styled.div`
  margin: 70px auto 0;
  font-size: ${typography.textSmall};
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: #999;
  ${Media.lessThan(Media.small)} {
    margin-top: ${spacing.small};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${mbSize(640)};
  }
`
export const PriceLightSection = styled(SubLightSection)`
  padding: 46px 0 94px 0;
`
export const H2 = styled.h2`
  margin: 0;
  color: white;
  font-size: ${typography.h1};
`

export const NewBillboard = styled.div`
  z-index: -1;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &.pc {
    ${hideMDDown};
  }
  &.mb {
    ${hideSMUp};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
