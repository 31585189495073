import React from 'react'
import { Row, Column } from '@renderbus/common/components'
import Calculator from '../newServices/calculator'
import InputWithSuffix from './InputWithSuffix'
import { shallowCompare } from '@renderbus/common/utils'
import { Input, Select, Label, Header, Title, Advanced, Icon, AdvancedWrapper } from '../newAtoms'
import Result from './result'

class GPUCard extends React.PureComponent {
  constructor(props) {
    super(props)
    const selectedGraphicsCard = Calculator.GPUSKU.defaultGraphicsCard
    const selectedCard = Calculator.GPUSKU.defaultCard
    const selectedMember = Calculator.GPUSKU.defaultMember
    const openAdvanced = false
    const machine = props.machine
    this.state = {
      selectedCard,
      selectedGraphicsCard,
      selectedMember,
      openAdvanced,
      machine,
    }
  }
  get graphicsCard() {
    return Calculator.GPUSKU.getGraphicsCard
  }
  get cardList() {
    return Calculator.GPUSKU.getCardList
  }
  get memberList() {
    return Calculator.GPUSKU.getMember
  }
  get getMemberPrice() {
    return Calculator.GPUSKU.getMemberPrice(this.state.selectedMember)
  }
  get getScores() {
    return Calculator.GPUSKU.getScores(this.state.selectedGraphicsCard)
  }
  handleGraphicsCardChange = event => {
    const selectedGraphicsCard = event.target.value
    this.setState({ selectedGraphicsCard })
  }
  handleCardChange = event => {
    const selectedCard = event.target.value
    this.setState({ selectedCard })
  }
  handleMemberChange = event => {
    const selectedMember = event.target.value
    this.setState({ selectedMember })
  }
  handleMechine = p => {
    this.setState({ machine: p.machine })
  }
  handleSelectMember = p => {
    this.setState({ selectedMember: p.selectedMember })
  }
  handleChange = event => {
    const handleMap = new Map([
      ['machine', 'onMachineChange'],
      ['frames', 'onFramesChange'],
      ['times', 'onTimesChange'],
    ])
    const value = Number(event.target.value)
    const handler = handleMap.get(event.target.name)
    this.props[handler](Number.isNaN(value) ? this.props[event.target.name] : value)
  }
  handleClick = event => {
    this.setState({ openAdvanced: this.state.openAdvanced ? false : true })
  }
  get result() {
    const { selectedCard } = this.state
    const { frames, times, memberList } = this.props
    const price = this.getMemberPrice
    const scores = this.getScores
    const card = selectedCard
    const machine = this.state.machine
    return {
      ...Calculator.calcGPUResult({ price, frames, times, machine, scores, card, memberList }),
      machine,
    }
  }
  componentDidMount() {
    let result = this.result
    this.props.onGPUChange({
      ...result,
      machine: this.props.machine,
      memberList: this.memberList,
      selectedMember: this.state.selectedMember,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    let result = this.result
    if (!shallowCompare(prevState, this.state) || !shallowCompare(prevProps, this.props)) {
      this.props.onGPUChange({
        ...result,
        machine: this.props.machine,
        memberList: this.memberList,
        selectedMember: this.state.selectedMember,
      })
    }
  }
  render() {
    const { selectedGraphicsCard, selectedCard } = this.state
    const { frames, times } = this.props
    return (
      <React.Fragment>
        <Title>GPU渲染成本估算</Title>
        <Header>您的机器配置</Header>
        <Row style={{ alignItems: 'center' }}>
          <Column lg='6' style={{ paddingLeft: 0 }}>
            <Label style={{ width: '4.5em' }}>型号</Label>
            <Select value={selectedGraphicsCard} onChange={this.handleGraphicsCardChange}>
              {this.graphicsCard.map((b, index) => (
                <option key={index} value={b}>
                  {b}
                </option>
              ))}
            </Select>
          </Column>
          <Column lg='6' style={{ padding: '8px 0' }}>
            <Label style={{ width: '7em' }}>显卡数</Label>
            <Select value={selectedCard} onChange={this.handleCardChange}>
              {this.cardList.map(b => (
                <option key={b} value={b}>
                  {b}
                </option>
              ))}
            </Select>
          </Column>
        </Row>
        <Header>您的渲染量</Header>
        <Row style={{ alignItems: 'center' }}>
          <Column lg='6' style={{ paddingLeft: 0, maxWidth: '46%' }}>
            <Label style={{ width: '4.5em' }}>帧数</Label>
            <Input type='text' value={frames} name='frames' onChange={this.handleChange} />
          </Column>
          <Column lg='6' style={{ padding: '8px 0', maxWidth: '54%', flexBasis: '54%' }}>
            <Label style={{ width: '9em' }} for='gpu-card-times-input'>
              每帧本地渲染时间
            </Label>
            <InputWithSuffix
              suffix='分'
              type='text'
              value={times}
              name='times'
              id='gpu-card-times-input'
              onChange={this.handleChange}
            />
          </Column>
        </Row>
        <Result
          result={this.result}
          onHandleMechine={this.handleMechine}
          onHandleSelectMember={this.handleSelectMember}
        ></Result>
      </React.Fragment>
    )
  }
}

export default GPUCard
