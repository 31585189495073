import React from 'react'
import styled from 'styled-components'
import { Input } from '../newAtoms'

const InputWithSuffixWrapper = styled.div`
  position: relative;
  display: inline-block;
  font-size: 0.875rem;

  & > span {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    user-select: none;
  }
`

const InputWithSuffix = ({ suffix, ...otherProps }) => {
  const inputRef = React.useRef(null)
  const handleClick = () => {
    inputRef.current && inputRef.current.focus()
  }
  return (
    <InputWithSuffixWrapper>
      <Input ref={inputRef} {...otherProps} />
      {suffix !== undefined && <span onClick={handleClick}>{suffix}</span>}
    </InputWithSuffixWrapper>
  )
}

export default InputWithSuffix
