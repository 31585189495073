import React from 'react'
import { Wrapper, Row, Column } from '@renderbus/common/components'

import CPUCard from './newComponents/cpu-card'
import GPUCard from './newComponents/gpu-card'
import Calculator from './newServices/calculator'
import { CpuCardWrapper } from './newAtoms'

class PriceCalculator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      times: 5, // 时间
      frames: 500, // 帧数
      machine: 500, // 机器数
      gpuTimes: 5, // 时间
      gpuFrames: 500, // 帧数
      gpuMachine: 500, // 机器数
      result: {
        userLocalRenderTimes: 0,
        selectedMember: 0,
        userRenderCost: 0,
        machine: 500,
        renderbusRenderTimes: 0,
        memberList: [],
        selectedModel: '',
      },
      gpuResult: {
        userLocalRenderTimes: 0,
        selectedMember: 0,
        userRenderCost: 0,
        machine: 500,
        renderbusRenderTimes: 0,
        memberList: [],
        selectedModel: '',
      },
    }
  }
  handleFramesChange = frames => {
    let machine = Calculator.CPUSKU.getDefaultMachineNumber(frames)
    this.setState({ frames, machine })
  }
  handleTimesChange = times => {
    this.setState({ times })
  }
  handleMachineChange = machine => {
    this.setState({ machine: machine > 5000 ? 5000 : machine })
  }
  handleGPUFramesChange = gpuFrames => {
    let gpuMachine = Calculator.GPUSKU.getDefaultMachineNumber(gpuFrames)
    this.setState({ gpuFrames, gpuMachine })
  }
  handleGPUTimesChange = gpuTimes => {
    this.setState({ gpuTimes })
  }
  handleGPUMachineChange = gpuMachine => {
    this.setState({ gpuMachine: gpuMachine > 1000 ? 1000 : gpuMachine })
  }
  handleSKUChange = result => {
    this.setState({ result })
  }
  handleGPUSKUChange = gpuResult => {
    this.setState({ gpuResult })
  }
  render() {
    const { times, frames, machine, gpuFrames, gpuTimes, gpuMachine } = this.state
    return (
      <Wrapper>
        <Row spacing={1}>
          <Column lg='6'>
            <CpuCardWrapper>
              <CPUCard
                frames={frames}
                times={times}
                machine={machine}
                isCPU
                onCPUChange={this.handleSKUChange}
                onTimesChange={this.handleTimesChange}
                onFramesChange={this.handleFramesChange}
                onMachineChange={this.handleMachineChange}
              />
            </CpuCardWrapper>
          </Column>
          <Column lg='6'>
            <CpuCardWrapper>
              <GPUCard
                frames={gpuFrames}
                times={gpuTimes}
                machine={gpuMachine}
                onGPUChange={this.handleGPUSKUChange}
                onTimesChange={this.handleGPUTimesChange}
                onFramesChange={this.handleGPUFramesChange}
                onMachineChange={this.handleGPUMachineChange}
              />
            </CpuCardWrapper>
          </Column>
        </Row>
      </Wrapper>
    )
  }
}

export default PriceCalculator
