import React from 'react'
import { Row, Column } from '@renderbus/common/components'
import InputWithSuffix from './InputWithSuffix'
import Calculator from '../newServices/calculator'
import { shallowCompare } from '@renderbus/common/utils'
import { Input, Select, Label, Header, Title, Advanced, Icon, AdvancedWrapper } from '../newAtoms'
import Result from './result'

class CPUCard extends React.PureComponent {
  constructor(props) {
    super(props)
    const selectedModel = Calculator.CPUSKU.defaultProcessor
    const selectedMember = Calculator.CPUSKU.defaultMember
    const openAdvanced = false
    const machine = props.machine
    this.state = {
      selectedModel,
      selectedMember,
      openAdvanced,
      machine,
    }
  }
  get modelList() {
    return Calculator.CPUSKU.getProcessor()
  }
  get memberList() {
    return Calculator.CPUSKU.getMember
  }
  get getMemberPrice() {
    return Calculator.CPUSKU.getMemberPrice(this.state.selectedMember)
  }
  get getScores() {
    return Calculator.CPUSKU.getScores(this.state.selectedModel)
  }
  get getGHz() {
    return Calculator.CPUSKU.getGHz(this.state.selectedModel)
  }
  handleModelChange = event => {
    const selectedModel = event.target.value
    this.setState({ selectedModel })
  }
  handleMemberChange = event => {
    const selectedMember = event.target.value
    this.setState({ selectedMember })
  }
  handleChange = event => {
    const handleMap = new Map([
      ['machine', 'onMachineChange'],
      ['frames', 'onFramesChange'],
      ['times', 'onTimesChange'],
    ])
    const value = Number(event.target.value)
    const handler = handleMap.get(event.target.name)
    this.props[handler](Number.isNaN(value) ? this.props[event.target.name] : value)
  }
  handleClick = event => {
    this.setState({ openAdvanced: this.state.openAdvanced ? false : true })
  }
  get result() {
    const { frames, times } = this.props
    const price = this.getMemberPrice
    const scores = this.getScores
    const GHz = this.getGHz
    const machine = this.state.machine
    return {
      ...Calculator.calcCPUResult({ price, frames, times, machine, scores, GHz }),
      machine,
      frames,
      times,
      scores,
      GHz,
    }
  }

  handleMechine = p => {
    this.setState({ machine: p.machine })
  }
  handleSelectMember = p => {
    this.setState({ selectedMember: p.selectedMember })
  }

  componentDidMount() {
    let result = this.result
    this.props.onCPUChange({
      ...result,
      machine: this.props.machine,
      memberList: this.memberList,
      selectedMember: this.state.selectedMember,
    })
  }
  render() {
    const { selectedModel } = this.state
    const { frames, times } = this.props
    return (
      <React.Fragment>
        <Title>CPU渲染成本估算</Title>
        <Header>您的机器配置</Header>
        <Row style={{ alignItems: 'center' }}>
          <Column lg='6' style={{ paddingLeft: 0 }}>
            <Label style={{ minWidth: '4.5em' }}>型号</Label>
            <Select value={selectedModel} onChange={this.handleModelChange}>
              {this.modelList.map((b, index) => (
                <option key={index} value={b}>
                  {b}
                </option>
              ))}
            </Select>
          </Column>
          <Column lg='6' style={{ padding: '8px 0' }}>
            <Label style={{ width: '7em' }}>主频</Label>
            <Input value={this.getGHz} disabled />
          </Column>
        </Row>
        <Header>您的渲染量</Header>
        <Row style={{ alignItems: 'center' }}>
          <Column lg='6' style={{ paddingLeft: 0, maxWidth: '46%' }}>
            <Label style={{ minWidth: '4.5em' }}>帧数</Label>
            <Input type='text' value={frames} name='frames' onChange={this.handleChange} />
          </Column>
          <Column lg='6' style={{ padding: '8px 0', maxWidth: '54%', flexBasis: '54%' }}>
            <Label style={{ width: '9em' }} for='cpu-card-times-input'>
              每帧本地渲染时间
            </Label>
            <InputWithSuffix
              suffix='分'
              type='text'
              value={times}
              id='cpu-card-times-input'
              name='times'
              onChange={this.handleChange}
            />
          </Column>
        </Row>
        <Result
          result={this.result}
          isCPU={true}
          onHandleMechine={this.handleMechine}
          onHandleSelectMember={this.handleSelectMember}
        ></Result>
      </React.Fragment>
    )
  }
}

export default CPUCard
