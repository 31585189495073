import styled, { css } from 'styled-components'
import { Flex } from '@renderbus/common/components'
import { color, transition, typography, spacing, Media } from '@renderbus/common/theme'

export const Super = styled.sup`
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2;
`
export const PriceContainer = styled.div`
  color: ${color.primary};
  transition: ${transition('color')};
`
export const CardContainer = styled(Flex)`
  height: 100%;
  text-align: center;
  flex-direction: column;
  color: ${color.prompt};
  padding: ${spacing.base} 0;
  font-size: ${typography.textSmall};
  background-color: ${p => (p.bg === 'dark' ? color.background : color.panel)};
  transition: ${transition(['background-color', 'color'])};
  > p {
    line-height: 1;
    margin: 0 0 1em;
  }
  > a {
    margin: 1.5em 0;
  }
  h2 {
    margin: 1em 0;
  }
  ${p =>
    p.isActive &&
    css`
      color: white;
      background-color: ${color.primary};
      ${PriceContainer} {
        color: white;
      }
    `}
  ${Media.lessThan(Media.small)} {
    a {
      padding: 0 1em;
    }
    h2 {
      margin: 0.5em 0;
      font-size: ${typography.h3};
    }
  }
`
export const PriceTableContainer = styled.div`
  display: flex;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
  }
`
