import React from 'react'
import PropTypes from 'prop-types'
import { CPUPriceList, GPUPriceList } from '../price-list'
import PriceTableRow from './price-table-row'
import { spacing } from '@renderbus/common/theme'
import { PriceTableContainer } from '../atoms'

export class PriceTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.imgRef = React.createRef()
    this.state = {
      defaultHover: 2,
    }
  }
  componentDidMount() {
    this.PreImg()
  }

  PreImg() {
    const { type } = this.props
    const priceList = type === 'cpu' ? CPUPriceList : GPUPriceList
    priceList.forEach(price => {
      const img = document.createElement('img')
      img.style.display = 'none'
      img.src = price.bgHover
      document.body.appendChild(img)
      img.onload = () => {
        document.body.removeChild(img)
      }
    })
  }
  resetDefaultHover = () => {
    this.setState({
      defaultHover: -1,
    })
  }
  render() {
    const { defaultHover } = this.state
    return (
      <PriceTableContainer style={{ marginTop: spacing.large }}>
        {this.props.type === 'cpu'
          ? CPUPriceList.map((price, index) => {
              return (
                <PriceTableRow
                  price={price}
                  key={price.title}
                  resetDefaultHover={this.resetDefaultHover}
                  defaultHover={index === defaultHover}
                  isCPU
                />
              )
            })
          : GPUPriceList.map((price, index) => {
              return (
                <PriceTableRow
                  price={price}
                  key={price.title}
                  defaultHover={index === defaultHover}
                  resetDefaultHover={() => this.setState({ defaultHover: -1 })}
                />
              )
            })}
      </PriceTableContainer>
    )
  }
}

PriceTable.propTypes = {
  type: PropTypes.oneOf(['cpu', 'gpu']),
}
