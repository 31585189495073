import React from 'react'
import {
  RenderDuration,
  RenderResultCard,
  MerchineInput,
  MerchineInputWrapper,
  MemberSelectWrapper,
  MemberSelectOptionWrapper,
} from '../newAtoms'
import { Flex } from '@renderbus/common/components'
import Calculator from '../newServices/calculator'
import { DurationFormat } from '@renderbus/common/utils/process-text'
class Result extends React.PureComponent {
  constructor(props) {
    super(props)
    const selectedMember = Calculator.CPUSKU.defaultMember
    const { result, isCPU = false } = props
    this.state = {
      isCPU,
      showMemberOption: false,
      selectedMember,
      ...result,
    }
  }
  get memberList() {
    return this.state.isCPU ? Calculator.CPUSKU.getMember : Calculator.GPUSKU.getMember
  }
  get getMemberPrice() {
    return this.state.isCPU
      ? Calculator.CPUSKU.getMemberPrice(this.state.selectedMember)
      : Calculator.GPUSKU.getMemberPrice(this.state.selectedMember)
  }
  get result() {
    const { frames, times, scores, GHz } = this.props.result
    const machine = this.state.machine
    const price = this.getMemberPrice
    return Calculator.calcCPUResult({ price, frames, times, machine, scores, GHz })
  }
  handleMemberChange = event => {
    const selectedMember = event.target.getAttribute('value')
    this.setState({ selectedMember })
    this.props.onHandleSelectMember({ selectedMember })
  }
  handleClickSelectMember = showMemberOption => {
    this.setState({
      showMemberOption,
    })
  }
  handleMechineChange = event => {
    const value = Number(event.target.value)
    const _value = Number.isNaN(value) ? this.props.result.machine : value
    const machine = _value > 5000 ? 5000 : _value
    this.setState({ machine, userRenderCost: this.result.userRenderCost })
    this.props.onHandleMechine({ machine })
  }
  componentDidUpdate() {
    if (
      this.props.result.machine !== this.state.machine ||
      this.props.result.selectedMember !== this.state.selectedMember
    ) {
      this.setState({
        ...this.props.result,
        ...this.state,
      })
    }
  }
  render() {
    const { result } = this.props
    const { machine, selectedMember, showMemberOption } = this.state
    const memberList = this.memberList
    const local_duration = DurationFormat(result.userLocalRenderTimes)
    const duration_1 = DurationFormat(result.renderbusRenderTimes)

    return (
      <React.Fragment>
        <RenderDuration>
          <span>本地渲染时长</span>
          <span className='duration'>
            <span className='big'>{local_duration.hour}</span>
            <span className='small'>小时</span>
            <span className='big'>{local_duration.min}</span>
            <span className='small'>分</span>
          </span>
        </RenderDuration>
        <Flex halign='flex-start'>
          <RenderResultCard>
            <div className='title'>Renderbus渲染时长</div>
            <div className='value'>
              <span className='big'>{duration_1.hour}</span>
              <span className='small'>小时</span>
              <span className='big'>{duration_1.min}</span>
              <span className='small'>分</span>
            </div>
            <MerchineInputWrapper>
              <MerchineInput type='text' value={machine} onChange={this.handleMechineChange} />
              台机器
            </MerchineInputWrapper>
          </RenderResultCard>
          <RenderResultCard>
            <div className='title'>渲染费用</div>
            <div className='value'>
              <span className='big'>{result.userRenderCost}</span>
              <span className='small'>元</span>
            </div>
            <MemberSelectWrapper onClick={() => this.handleClickSelectMember(!showMemberOption)}>
              {memberList[Number(selectedMember)]}
              <span />
              <MemberSelectOptionWrapper
                showMemberOption={showMemberOption}
                onClick={this.handleMemberChange}
                onMouseLeave={() => this.handleClickSelectMember(false)}
              >
                {this.memberList.map((b, index) => (
                  <span key={b} value={index}>
                    {b}
                  </span>
                ))}
              </MemberSelectOptionWrapper>
            </MemberSelectWrapper>
          </RenderResultCard>
        </Flex>
      </React.Fragment>
    )
  }
}

export default Result
