/**
 * pricing页面处理时长
 * @param {*} timeString  例如 41小时40分
 * @returns { hour: 42, min: 40}
 */
export const DurationFormat = timeString => {
  if (timeString == '0') return { hour: 0, min: 0 }
  const result = {
    hour: '0',
    min: '0',
  }
  try {
    let regexResult = timeString.match(/(\d+)小时(\d+)分/)
    let hour = parseInt(regexResult[1])
    let min = parseInt(regexResult[2])
    result.hour = hour
    result.min = min
  } catch (error) {}
  return result
}
